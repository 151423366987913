import React, { useEffect, useState, useRef } from 'react';
import './DesignStudio.css';
import { Button, Col, Form, Row, Table, Pagination, InputGroup, FormControl } from 'react-bootstrap';
import { callApi } from "../utills/api";
import CreatableSelect from 'react-select/creatable';
import CreateIcon from '../../assets/design-studio/create-new.svg';
import OrderPattern from "../../assets/icons/order-pattern-icon.svg";
import FilterIcon from "../../assets/design-studio/filter-table.svg";
import NoDataIcon from "../../assets/design-studio/no-design.svg";
import UploadPattern from "../../assets/design-studio/upload-pattern.svg";
import UploadIcon from "../../assets/design-studio/upload-icon.svg";
import CloseModal from "../../assets/design-studio/close-modal.svg";
import SaveBtn from "../../assets/design-studio/save-btn.svg";
import MinusAdjustment from "../../assets/design-studio/max-adjustment.svg";
import CustomSize from "../../assets/icons/custom-size.svg";
import ToggleOpen from "../../assets/design-studio/toggle-eye-open.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import EditDesignIcon from "../../assets/design-studio/edit-design-icon.svg";
import DownloadIcon from "../../assets/design-studio/download-icon.svg";
import DeleteIcon from "../../assets/design-studio/delete-icon.svg";
import Upload from "../../assets/design-studio/upload.svg";
import { showError, showSuccess, showAlert } from "../utills/sweetAlert";
import ExcelComponent from './ExcelComponent';
import previous from "../../assets/previousicon.svg";
import next from "../../assets/nexticon.svg";

type DesignData = {
  id: string;
  name: string;
  master: string;
  parent: string;
  sub: string;
  child: string;
  saveStatus: string;
  image: string;
};

interface Size {
  sizeId: string;
  sizeValue: string;
}

type Item = {
  updateStatus: boolean;
  labelId: string;
  labelName: string;
  standardSize: string;
  standardSizeId: string;
  adjustmentMin: string;
  adjustmentMax: string;
  measurementId: string;
  typeId: string;
  toggleOpen: string;
  attachments: {
    deleteStatus: boolean;
    imageType: string;
    imageData: string;
  }[];
  categoryId: string;
  title: string;
  labelImage: string;
};

type Category = {
  id: string;
  name: string;
};

interface DesignCategory {
  id: string;
  categoryName: string;
  typeId: string;
  categoryIcon: string;
}

interface ComponentType {
  id: string;
  name: string;
  tags: string[];
  attachments: {
    deleteStatus: boolean;
    imageType: string;
    imageData: string;
  }[];
}

const DesignStudio1 = () => {
  const [designsData, setDesignsData] = useState<DesignData[]>([]);
  const [selectedDesigns, setSelectedDesigns] = useState<string[]>([]);
  const [isAnySelected, setIsAnySelected] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredDesigns, setFilteredDesigns] = useState(designsData);
  const [showModal, setShowModal] = useState(false);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countPage, setPageCount] = useState(1);
  const [firstPageNumber, setFirstPageNumber] = useState(1);
  const [secondPageNumber, setsecondPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    getData('');
  }, [itemsPerPage])

  useEffect(() => {
    setIsAnySelected(selectedDesigns.length > 0);
    console.log(selectedDesigns);
  }, [selectedDesigns]);

  const getData = (search: any) => {
    setSearchTerm(search);
    const formData = {
      page: countPage,
      limit: itemsPerPage,
      searchTerm: search
    };
    console.log("formData", formData)
    callApi("POST", "designSelect.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        setDesignsData(response.data.data);
        setFilteredDesigns(response.data.data);
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const Previous = () => {
    const PreviousPageCount = countPage - 1;
    console.log("PreviousPageCount:", PreviousPageCount);
    setFirstPageNumber(PreviousPageCount);

    const formData = {
      page: PreviousPageCount,
      limit: itemsPerPage,
      searchTerm: searchTerm
    };
    console.log("formData", formData)
    callApi("POST", "designSelect.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        setPageCount(response.page);
        setTotalRecords(response.totalRecords);
        const PageCount = Math.round(response.totalRecords / response.limit);
        console.log("PageCount:", PageCount);
        setsecondPageNumber(PageCount);
        setDesignsData(response.data.data);
        setFilteredDesigns(response.data.data);
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const Nextpage = () => {
    const NextPageCount = countPage + 1;
    console.log("NextPageCount:", NextPageCount);
    setFirstPageNumber(NextPageCount);
    const formData = {
      page: NextPageCount,
      limit: itemsPerPage,
      searchTerm: searchTerm
    };
    console.log("formData", formData)
    callApi("POST", "designSelect.php", formData)
      .then((response: any) => {
        setPageCount(response.page);
        setTotalRecords(response.totalRecords);
        const PageCount = Math.round(response.totalRecords / response.limit);
        console.log("PageCount:", PageCount);
        setsecondPageNumber(PageCount);
        console.log("Form submitted successfully:", response);
        setDesignsData(response.data.data);
        setFilteredDesigns(response.data.data);
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleSelectDesign = (designId: any) => {
    setSelectedDesigns((prevSelected: any) =>
      prevSelected.includes(designId)
        ? prevSelected.filter((id: any) => id !== designId)
        : [...prevSelected, designId]
    );
  };

  const handleSelectAll = (e: any) => {
    if (e.target.checked) {
      setSelectedDesigns(designsData.map((design) => design.id));
    } else {
      setSelectedDesigns([]);
    }
  };

  const handleDelete = () => {
    const deletedIds = selectedDesigns.map(id => Number(id));
    const formData = {
      deletedIds: deletedIds
    };
    console.log("formData", formData)
    callApi("POST", "deleteDesign.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        showSuccess("Success!", "Deleted Successfully!");
        setIsAnySelected(false);
        setSelectedDesigns([]);
        getData('');
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const handlePageChange = (e: any) => setItemsPerPage(Number(e.target.value));

  const toggleModal = () => setShowModal(!showModal);

  const selectedDesignData = designsData.filter(design => selectedDesigns.includes(design.id));

  const changeSatus = async (designId: any, designStatus: any) => {
    const isComfirm = await showAlert("Please Confirm", "Are you sure you want to change the status?", "Yes", "No")
    if (isComfirm) {
      statusChange(designId, designStatus);
    }
  }

  const statusChange = (designId: any, designStatus: any) => {
    let status = designStatus == 'draft' ? 'live' : 'draft'
    const formData = {
      id: designId,
      status: status
    };
    console.log("formData", formData)
    callApi("POST", "designStatusUpdate.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        showSuccess("Success!", "Updated Successfully!");
        getData('');
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  useEffect(() => {
    console.log(showModal);
  }, [showModal])


  if (designsData.length === 0 && searchTerm === '') {
    return (
      <div className="studio-container no-designs">
        <div className="studio-header">
          <span className="studio-title">All Designs (0)</span>
        </div>
        <div className="empty-state">
          <img src={NoDataIcon} alt="No Designs" />
          <p>It seems there are no designs created yet!</p>
          <Button className="create-button" variant="primary" onClick={toggleModal}>
            <img src={CreateIcon} alt="create-icon" /> Create New
          </Button>
          {showModal && <CreateDesignModal onClose={toggleModal} onSubmit={(data) => getData(data)} />}
        </div>
      </div>
    );
  }

  return (
    <div className="studio-container">
      <div className="studio-header">
        <span className="studio-title">All Designs ({designsData.length})</span>
        <Button className="create-button" variant="primary" onClick={toggleModal}>
          <img src={CreateIcon} alt="create-icon" /> Create New
        </Button>
        {showModal && <CreateDesignModal onClose={toggleModal} onSubmit={(data) => getData(data)} />}
      </div>
      <div className='header-divider'></div>
      <div className="element-wrapper">
        <Row className="mb-3">
          <Col md={6} style={{ display: 'flex', alignItems: 'center', paddingLeft: '3%' }}>
            <div className="item-per-page">
              <label>Show</label>
              <Form.Select
                className="form-control select-width"
                onChange={(e) => { handlePageChange(e) }}
              >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
                <option value="40">40</option>
                <option value="50">50</option>
              </Form.Select>
              <span>per page</span>
            </div>
            <div className="page-navigation">
              <span>{firstPageNumber} to {secondPageNumber}</span>
              <button onClick={Previous} disabled={firstPageNumber === 1} // Disable if it's the first page
                style={{ cursor: firstPageNumber === 1 ? 'not-allowed' : 'pointer' }}>
                <img src={previous} alt="previous" />
              </button>
              <button onClick={Nextpage} disabled={totalRecords === itemsPerPage || totalRecords < itemsPerPage } // Disable if it's the first page
                style={{ cursor: totalRecords === itemsPerPage || totalRecords < itemsPerPage ? 'not-allowed' : 'pointer' }}>
                <img src={next} alt="next" />
              </button>
            </div>
          </Col>
          <Col md={6} className='action-left'>
            <InputGroup>
              <FormControl
                placeholder="Search designs"
                value={searchTerm}
                onChange={(e) => getData(e.target.value)}
              />
            </InputGroup>
          </Col>
        </Row>
        <Table responsive className="order-status-table">
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>
                <Form.Check
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={selectedDesigns.length === designsData.length}
                />
              </th>
              <th>Design Name</th>
              <th>Design ID</th>
              <th>Pattern</th>
              <th>Category</th>
              <th>Sub Category</th>
              <th>Sub Category</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {isAnySelected && (
              <tr>
                <td colSpan={8} style={{ padding: '0px', textAlign: 'center' }}>
                  <Row style={{ background: '#FFEB3433', padding: '5px 25px' }}>
                    <Col style={{ display: 'flex', alignItems: 'center' }}>
                      <Form.Check
                        type="checkbox"
                        checked={selectedDesigns.length > 0}
                        onClick={() => setSelectedDesigns([])}
                      />
                      <div style={{ marginLeft: '2.5%', fontSize: '14px', fontWeight: '500', color: '#0072B9' }}>
                        {selectedDesigns.length} Selected Designs
                      </div>
                      <div
                        style={{ backgroundColor: '#fff', padding: '3px', marginLeft: '2.5%', width: '80px', height: '28px', borderRadius: '4px', textAlign: 'center' }}
                        onClick={handleDelete}
                      >
                        <img src={DeleteIcon} alt="icon" style={{ marginRight: '3px', marginTop: '-4px' }} /> Delete
                      </div>
                      <div
                        style={{ backgroundColor: '#fff', padding: '3px', marginLeft: '2.5%', width: '100px', height: '28px', borderRadius: '4px', textAlign: 'center' }}

                      >
                        <img src={DownloadIcon} alt="icon" style={{ marginRight: '5px', marginTop: '-4px' }} /> Download
                        <ExcelComponent data={selectedDesignData} />
                      </div>
                    </Col>
                  </Row>
                </td>
              </tr>
            )}
            {designsData.map((design) => (
              <tr key={design.id}>
                <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Form.Check
                    type="checkbox"
                    onChange={() => handleSelectDesign(design.id)}
                    checked={selectedDesigns.includes(design.id)}
                  />
                </td>
                <td style={{ textWrap: 'nowrap' }}>{design.name}</td>
                <td>{design.id}</td>
                <td>
                  <img
                    src={design.image ? design.image : OrderPattern}
                    alt="pattern"
                    style={{ width: '30px', height: '30px', position: 'inherit' }}
                  />
                </td>
                <td>{design.master}</td>
                <td>{design.parent}</td>
                <td>{design.sub}</td>
                <td>
                  <span
                    style={{
                      backgroundColor:
                        design.saveStatus === 'draft' ? '#15C5732E' : '#0072B92E',
                      color: design.saveStatus === 'draft' ? '#15C573' : '#0072B9',
                      padding: '5px 15px',
                      borderRadius: '30px',
                      fontSize: '14px',
                      fontWeight: '500'
                    }}
                    onClick={() => changeSatus(design.id, design.saveStatus)}
                  >
                    {design.saveStatus == 'draft' ? 'Saved' : 'Published'}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

const CreateDesignModal = ({ onClose, onSubmit }: { onClose: () => void; onSubmit: (data: any) => void; }) => {
  const [progress, setProgress] = useState({
    selectCategory: 10,
    itemInformation: 0,
    customComponent: 0,
    customDesign: 0,
  });
  const [currentStep, setCurrentStep] = useState(1);
  //Select Category State
  const [designName, setDesignName] = useState('');
  const [childCategory, setChildCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const [parentCategory, setParentCategory] = useState('');
  const [masterCategory, setMasterCategory] = useState('');
  const [iconFiles, setIconFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [frontFiles, setFrontFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [backFiles, setBackFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [previewFront, setPreviewFront] = useState<string | null>(null);
  const [previewBack, setPreviewBack] = useState<string | null>(null);
  const [categories, setCategories] = useState<{
    masterCategories: Category[];
    parentCategories: Category[];
    subCategories: Category[];
    childCategories: Category[];
  }>({
    masterCategories: [],
    parentCategories: [],
    subCategories: [],
    childCategories: [],
  });
  const [errors, setErrors] = useState({
    designName: '',
    masterCategory: '',
    parentCategory: '',
    subCategory: '',
    childCategory: '',
  });

  //Item Informations State
  const [items, setItems] = useState<Item[]>([
    { updateStatus: false, labelId: '', labelName: '', standardSize: '', standardSizeId: '', adjustmentMin: '', adjustmentMax: '', measurementId: '', typeId: '', toggleOpen: '0', attachments: [], categoryId: '', title: '', labelImage: '' },
  ]);
  const [activeButton, setActiveButton] = useState('IND');
  const [activeSizeButton, setActiveSizeButton] = useState('in');
  const [selected, setSelected] = useState<string | null>(null);
  const [selectedSize, setSelectedSize] = useState<string | null>(null);
  const [size, setSize] = useState('');
  const [labelData, setLabelData] = useState<any[]>([]);
  const [availableSizes, setAvailableSizes] = useState<Size[]>([]);
  const [removedIds, setRemovedIds] = useState<number[]>([]);

  //CustomComponent State 
  const [preferredMaterials, setPreferredMaterials] = useState<string[]>([]);
  const [inputs, setInputs] = useState([{ name: '' }]);
  const [componentError, setComponentError] = useState('');
  const [materialError, setMaterialError] = useState('');

  //CustomDesign State
  const [designCategories, setDesignCategories] = useState<DesignCategory[]>([]);
  const [frontDesignFiles, setFrontDesignFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [backDesignFiles, setBackDesignFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [handFiles, setHandFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [neckFiles, setNeckFiles] = useState<{ deleteStatus: boolean; imageType: string; imageData: string; }[]>([]);
  const [previewDesignFront, setPreviewDesignFront] = useState('');
  const [previewDesignBack, setPreviewDesignBack] = useState('');
  const [previewHand, setPreviewHand] = useState('');
  const [previewNeck, setPreviewNeck] = useState('');

  //PostCUstomDesign State
  const [customError, setCustomError] = useState<string>('');
  const [components, setComponents] = useState<ComponentType[]>([
    { id: '', name: '', tags: [], attachments: [{ deleteStatus: false, imageType: '', imageData: '' }] }
  ]);

  //Select Category Functions
  useEffect(() => {
    CategorypageShowApi();
  }, []);

  const getData = (search: any) => {
    const formData = {
      page: 1,
      limit: 10,
      searchTerm: ''
    };
    console.log("formData", formData)
    callApi("POST", "designSelect.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        onSubmit('');
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const CategorypageShowApi = () => {
    callApi("GET", "categorySelect.php", "")
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response) {
          setCategories(response);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const validateFields = () => {
    const newErrors = {
      designName: designName ? '' : '*Design name is required*',
      masterCategory: masterCategory ? '' : '*Master category is required*',
      parentCategory: parentCategory ? '' : '*Parent category is required*',
      subCategory: subCategory ? '' : '*Sub category is required*',
      childCategory: childCategory ? '' : '*Child category is required*',
    };

    setErrors(newErrors);
    setProgress((prevState) => ({
      ...prevState,
      selectCategory: 70,
    }));
    return Object.values(newErrors).every((error) => error === '');
  };

  const handleSelectCategorySubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!validateFields()) {
      return;
    }
    const formData = {
      designName: designName,
      masterId: masterCategory,
      parentId: parentCategory,
      subId: subCategory,
      childId: childCategory,
      iconAttachments: iconFiles,
      frontAttachments: frontFiles,
      backAttachments: backFiles
    };
    sessionStorage.setItem('parentId', parentCategory);
    console.log("formData", formData)
    callApi("POST", "designStudioInsert.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        if (response.data.success && response.data.insertId) {
          sessionStorage.setItem('designId', response.data.insertId.toString());
          sessionStorage.setItem('designName', designName);
          setCurrentStep(2);
          getLabels();
          setProgress((prevProgress) => ({
            ...prevProgress,
            selectCategory: 100,
            itemInformation: 10
          }));
        }
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleIconClick = () => {
    const fileInput = document.getElementById('fileInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFrontBrowseClick = () => {
    const fileInput = document.getElementById('frontInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleBackBrowseClick = () => {
    const fileInput = document.getElementById('backInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setIconFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewImage(reader.result as string);
      };
    }
  };

  const handleFrontFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setFrontFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewFront(reader.result as string);
      };
    }
  };

  const handleBackFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setBackFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewBack(reader.result as string);
      };
    }
  };

  const handleDropFront = (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setFrontFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewFront(reader.result as string);
      };
    }
  };

  const handleDropBack = (event: React.DragEvent) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setBackFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewBack(reader.result as string);
      };
    }
  };

  const handleDragOver = (event: React.DragEvent) => {
    event.preventDefault();
  };

  const handleDragLeave = (event: React.DragEvent) => {
    event.preventDefault();
  };

  //Item Information Functions
  useEffect(() => {
    getLabels();
  }, [activeButton]);

  const getLabels = () => {
    const formData = {
      categoryId: sessionStorage.getItem('parentId'),
      countryCode: activeButton,
    }
    callApi("POST", "designSizeSelect.php", formData)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response) {
          setLabelData(response.label);
          setSelected(response.label[0].labelTitle);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getMeasurements = () => {
    const formData = {
      categoryId: sessionStorage.getItem('parentId'),
      countryCode: activeButton,
      title: selected,
      sizeId: selectedSize,
    }
    callApi("POST", "designLabelSizeSelect.php", formData)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response) {
          const initialItems = response.data.map((label: any) => ({
            updateStatus: label.updateStatus,
            labelId: label.id,
            labelName: label.labelName,
            standardSize: label.standardSize || '',
            standardSizeId: label.standardSizeId || '',
            adjustmentMin: label.adjustmentMin || '',
            adjustmentMax: label.adjustmentMax || '',
            measurementId: label.measurementId || '',
            typeId: label.typeId || '',
            toggleOpen: '0',
            attachments: [],
            title: label.title,
            categoryId: label.categoryId,
            labelImage: label.labelImage
          }));
          setItems(initialItems);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (selected && selectedSize && activeButton) {
      getMeasurements();
    }
  }, [selected, selectedSize, activeButton]);

  const handleSelect = (option: string) => {
    setSelected(option);
    const labelObj = labelData.find((label: any) => label.labelTitle === option);
    if (labelObj && labelObj.sizes.length > 0) {
      setAvailableSizes(labelObj.sizes);
    } else {
      setAvailableSizes([]);
    }
  };

  useEffect(() => {
    if (selected) {
      const labelObj = labelData.find((label: any) => label.labelTitle === selected);
      if (labelObj && labelObj.sizes.length > 0) {
        setAvailableSizes(labelObj.sizes);
      } else {
        setAvailableSizes([]);
      }
    }
  }, [selected, labelData]);

  const addMoreItems = () => {
    let categoryId = sessionStorage.getItem('parentId') || '';
    setItems([
      ...items,
      { updateStatus: true, labelId: '', labelName: '', standardSize: '', standardSizeId: '', adjustmentMin: '', adjustmentMax: '', measurementId: '', typeId: '', toggleOpen: '0', attachments: [], categoryId: categoryId, title: selected || '', labelImage: '' }
    ]);
  };

  useEffect(() => {
    console.log("items", items)
  }, [items])

  const handleInputChange = (index: number, event: React.ChangeEvent<any>) => {
    const { name, value } = event.target as HTMLInputElement;
    const updatedItems = [...items];
    if (updatedItems[index][name as keyof Item] !== value) {
      updatedItems[index]['updateStatus'] = true;
    }
    updatedItems[index][name as keyof Item] = value as never;
    setItems(updatedItems);
  };

  const handleActiveButton = (buttonId: string) => {
    setActiveButton(buttonId);
    setSelectedSize('');
    if (labelData.length > 0) {
      const firstLabel = labelData[0].labelTitle;
      setSelected(firstLabel);
      setAvailableSizes(labelData[0].sizes);
    } else {
      setSelected(null);
      setAvailableSizes([]);
    }
  };

  const handleActiveSizeButton = (buttonId: string) => {
    setActiveSizeButton(buttonId);
  };

  const handleSizeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedSizeValue = e.target.value;
    setSize(selectedSizeValue);
    const selectedSizeObject = availableSizes.find((size: any) => size.sizeValue === selectedSizeValue);
    if (selectedSizeObject) {
      setSelectedSize(selectedSizeObject.sizeId);
    }
  };

  const toggleImage = (index: number) => {
    const updatedItems = [...items];
    updatedItems[index].toggleOpen = updatedItems[index].toggleOpen === '0' ? '1' : '0';
    setItems(updatedItems);
  };

  const removeItem = (index: number) => {
    const itemToRemove = items[index];
    setRemovedIds([...removedIds, parseInt(itemToRemove.labelId, 10)]);
    setItems(items.filter((_, i) => i !== index));
  };

  useEffect(() => {
    console.log(removedIds);
  }, [removedIds]);

  const handleItemFileChange = (event: any, index: number) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newFrontFile = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        const updatedItems = [...items];
        updatedItems[index].attachments = [...updatedItems[index].attachments, newFrontFile];
        updatedItems[index].updateStatus = true;
        setItems(updatedItems);
      };
    }
  };

  const handleItemBrowseClick = () => {
    const fileInput = document.getElementById('UploadImage');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleItemInformationSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    const formData = {
      sizeId: selectedSize,
      countryCode: activeButton,
      measurements: items.map(({ toggleOpen, ...rest }) => rest),
      deletedIds: removedIds,
    };
    console.log("formData", formData)
    callApi("POST", "designLabelUpdate.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        setCurrentStep(3);
        setProgress((prevProgress) => ({
          ...prevProgress,
          itemInformation: 80,
        }));
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const handleSubmission = (event: React.FormEvent) => {
    event.preventDefault();
    setCurrentStep(4);
    setProgress((prevProgress) => ({
      ...prevProgress,
      itemInformation: 100,
      customComponent: 10
    }));
    getComponents();
  }

  //Custom Component Functions
  const getComponents = () => {
    const formData = {
      designId: sessionStorage.getItem('designId'),
    }
    callApi("POST", "customComponentSelect.php", formData)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response) {
          const updatedInputs = response.components.length > 0
            ? response.components.map((component: any) => ({
              id: component.componentId,
              name: component.componentName,
            }))
            : [{ name: '' }];
          const preferredMaterialsArray = response.preferredMaterials.length > 0
            ? response.preferredMaterials[0].preferredMaterial.split(',')
            : [];
          setInputs(updatedInputs);
          setPreferredMaterials(preferredMaterialsArray);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleAddMore = () => {
    if (inputs.length === 0 || inputs[inputs.length - 1].name.trim() !== '') {
      setInputs([...inputs, { name: '' }]);
      setComponentError('');
    } else {
      setComponentError("*Please fill in the component name*");
    }
  };

  const handleChange = (newValue: any) => {
    const selectedValues = newValue.map((option: any) => option.value);
    setMaterialError('');
    setPreferredMaterials(selectedValues);
  };

  const handleComponentInputChange = (index: number, value: string) => {
    const updatedInputs = [...inputs];
    updatedInputs[index].name = value;
    setComponentError('');
    setInputs(updatedInputs);
  };

  const removeComponentItem = (index: number) => {
    setComponentError('');
    const updatedInputs = inputs.filter((_, i) => i !== index);
    setInputs(updatedInputs);
  };

  const handleCustomComponentSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (preferredMaterials.length === 0) {
      setMaterialError("*Please fill in the preferred materials*");
      return;
    }
    if (inputs.length === 0 || inputs.some(input => input.name.trim() === '')) {
      setComponentError("*Please fill in the component name*");
      return;
    }
    const formData = {
      designId: sessionStorage.getItem('designId'),
      preferredMaterial: preferredMaterials,
      components: inputs,
    };
    console.log("formData", formData)
    callApi("POST", "customComponentInsert.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        setCurrentStep(5);
        getDesigns();
        setProgress((prevState) => ({
          ...prevState,
          customComponent: 100,
          customDesign: 10
        }));
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  useEffect(() => {
    console.log(inputs)
    console.log(preferredMaterials)
  }, [inputs, preferredMaterials])

  //Custom Design Functionality
  const handleFrontDesignBrowseClick = () => {
    const fileInput = document.getElementById('frontDesignInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleBackDesignBrowseClick = () => {
    const fileInput = document.getElementById('backDesignInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleHandBrowseClick = () => {
    const fileInput = document.getElementById('handInput');
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleNeckBrowseClick = () => {
    const fileInput = document.getElementById('neckInput');
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleFrontDesignFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setFrontDesignFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewDesignFront(reader.result as string);
      };
    }
  };

  const handleBackDesignFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setBackDesignFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewDesignBack(reader.result as string);
      };
    }
  };

  const handleNeckFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setNeckFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewNeck(reader.result as string);
      };
    }
  };

  const handleHandFileChange = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64String = reader.result as string;
        const imageType = file.type.split("/")[1];
        console.log("image", base64String, imageType);
        const newIcon = {
          deleteStatus: false,
          imageType: imageType,
          imageData: base64String
        };
        setHandFiles((prevFiles) => [...prevFiles, newIcon]);
        setPreviewHand(reader.result as string);
      };
    }
  };

  useEffect(() => {
    getDesigns();
  }, []);

  const getDesigns = () => {
    const formData = {
      designId: sessionStorage.getItem('designId'),
    }
    callApi("POST", "designSelectedList.php", formData)
      .then((res: any) => res.data)
      .then((response: any) => {
        if (response) {
          setDesignCategories(response.List || []);
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleCustomDesignSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (frontDesignFiles.length === 0) {
      showError("Failed", "Please upload the image for Front.");
      return;
    }
    if (backDesignFiles.length === 0) {
      showError("Failed", "Please upload the image for Back.");
      return;
    }
    if (handFiles.length === 0) {
      showError("Failed", "Please upload the image Hand.");
      return;
    }
    if (neckFiles.length === 0) {
      showError("Failed", "Please upload the image for Neck.");
      return;
    }
    const formData = {
      designId: sessionStorage.getItem('designId'),
      frontImage: frontDesignFiles,
      backImage: backDesignFiles,
      handImage: handFiles,
      neckImage: neckFiles,
    };
    console.log("formData", formData)
    callApi("POST", "designImageUpdate.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        setCurrentStep(6);
        setProgress((prevProgress) => ({
          ...prevProgress,
          customDesign: 50, // Update selectCategory to 60%
        }));
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  const getClassName = (typeId: string) => {
    switch (typeId) {
      case '1':
        return 'super-card';
      case '2':
        return 'parent-card';
      case '3':
        return 'sub-card';
      case '4':
        return 'child-card';
      default:
        return 'child-card';
    }
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
      padding: '10px 0px',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9',
      },
    }),
  };

  //PostCustomDesign Functions
  const handleChangeTags = (newValue: any, index: number) => {
    const selectedValues = newValue.map((option: any) => option.value);
    const newComponents = [...components];
    newComponents[index].tags = selectedValues;
    setComponents(newComponents);
  };

  const handleComponentNameChange = (event: React.ChangeEvent<any>, index: number) => {
    const updatedName = event.target.value;
    const newComponents = components.map((component, i) => ({
      ...component,
      name: updatedName,
    }));
    setComponents(newComponents);
    if (updatedName !== '') {
      setCustomError('');
    }
  };

  const handleCustomAddMore = () => {
    if (!components[0].name.trim()) {
      setCustomError('*Please fill Component Name.*');
    } else {
      const newComponent = {
        id: '',
        name: components[0].name,
        tags: [],
        attachments: [{ deleteStatus: false, imageType: '', imageData: '' }]
      };
      setComponents([...components, newComponent]);
      setCustomError('');
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = e.target.files?.[0];
    if (file) {
      const newComponents = [...components];
      newComponents[index].attachments[0].imageType = file.type;
      const reader = new FileReader();
      reader.onloadend = () => {
        newComponents[index].attachments[0].imageData = reader.result as string;
        setComponents(newComponents);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleBrowseClick = (index: number) => {
    const fileInput = document.getElementById(`fileInput-${index}`);
    if (fileInput) {
      fileInput.click();
    }
  };

  const customLabelStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9',
      },
    }),
  };

  const handleSubmit = (event: React.FormEvent, status: any) => {
    event.preventDefault();
    const formData = {
      designId: sessionStorage.getItem('designId'),
      status: status,
      components: components,
    };
    console.log("formData", formData)
    callApi("POST", "customDesignInsertUpdate.php", formData)
      .then((response: any) => {
        console.log("Form submitted successfully:", response);
        onClose();
        sessionStorage.removeItem('designName');
        showSuccess("Success!", "Created Design Successfully!");
      })
      .catch((error: any) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <div className="modal-overlay">
      <img className='close-modal' src={CloseModal} onClick={onClose} alt="close-icon" />
      <div className="modal-content1">
        <div className="modal-header1">
          <div style={{ fontSize: '24px', fontWeight: '500', color: '#212121' }}>
            Create Design
          </div>
          <div className="modal-footer" style={{ marginRight: '2%' }}>
            {designName && (
              <div className="design-name-display" style={{ marginRight: '3%' }}>
                ({designName})
              </div>
            )}
            <Button className='save-btn' style={{ marginRight: '3%' }} onClick={(e) => { handleSubmit(e, 'draft') }}>Save</Button>
            <Button className='save-ctn-btn' disabled={currentStep < 5} onClick={(e) => { handleSubmit(e, 'live') }}>Save & Publish</Button>
          </div>
        </div>
        <div style={{ marginLeft: '0px', marginTop: '15px' }} className='header-divider'></div>
        <div className="modal-body">
          <Row style={{ marginRight: '1%' }}>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Select Category</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress.selectCategory}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Item Information</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress.itemInformation}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Custom Component</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress.customComponent}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md="3">
              <div className="procontainer">
                <div className="prolabels">
                  <span className="prolabel">Custom Design</span>
                </div>
                <div className="progress-container">
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress.customDesign}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <div className="modal-form">
            {currentStep === 1 && (
              <>
                <div className="design-form">
                  <div className="form-section">
                    <label className='form-label'>
                      Design Name*
                      <Form.Control type="text" value={designName} className='input'
                        onChange={(e) => {
                          setDesignName(e.target.value);
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            selectCategory: 20
                          }));
                        }} />
                      {errors.designName && <span className="error">{errors.designName}</span>}
                    </label>

                    <div className="design-icon">
                      <label className='form-label' style={{ marginBottom: '8px' }}>Design Icon</label>
                      <div className="icon-upload">
                        {!previewImage ? (
                          <div className='upload-before' onClick={handleIconClick} style={{ cursor: 'pointer' }}>
                            <img src={UploadIcon} alt="icon" />
                            <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                            Click to upload
                          </div>
                        ) : (
                          <>
                            <img
                              src={previewImage}
                              alt="Selected Icon"
                              style={{
                                width: "80px",
                                height: "52px",
                                border: "1px solid #ddd",
                                borderRadius: "4px",
                                objectFit: "contain"
                              }} />
                            <img src={EditDesignIcon}
                              style={{
                                width: "32px",
                                height: "32px",
                                marginLeft: '3%',
                                objectFit: "contain"
                              }} alt="icon" onClick={handleIconClick} />
                            <input
                              type="file"
                              id="fileInput"
                              accept="image/*"
                              style={{ display: "none" }}
                              onChange={handleFileChange}
                            />
                          </>
                        )}
                      </div>
                    </div>

                    <label className='form-label' style={{ marginBottom: '20px' }}>
                      Select Master Category*
                      <Form.Select aria-label="Default select example" value={masterCategory}
                        onChange={(e) => {
                          setMasterCategory(e.target.value);
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            selectCategory: 30
                          }));
                        }} >
                        <option value=""></option>
                        {categories.masterCategories.map((cat) => (
                          <option key={cat.id} value={cat.id}>{cat.name}</option>
                        ))}
                      </Form.Select>
                      {errors.masterCategory && <span className="error">{errors.masterCategory}</span>}
                    </label>

                    <label className='form-label' style={{ marginBottom: '20px' }}>
                      Select Parent Category*
                      <Form.Select aria-label="Default select example" value={parentCategory}
                        onChange={(e) => {
                          setParentCategory(e.target.value);
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            selectCategory: 40
                          }));
                        }} >
                        <option value=""></option>
                        {categories.parentCategories.map((cat) => (
                          <option key={cat.id} value={cat.id}>{cat.name}</option>
                        ))}
                      </Form.Select>
                      {errors.parentCategory && <span className="error">{errors.parentCategory}</span>}
                    </label>

                    <label className='form-label' style={{ marginBottom: '20px' }}>
                      Select Sub Category*
                      <Form.Select aria-label="Default select example" value={subCategory}
                        onChange={(e) => {
                          setSubCategory(e.target.value);
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            selectCategory: 50
                          }));
                        }} >
                        <option value=""></option>
                        {categories.subCategories.map((cat) => (
                          <option key={cat.id} value={cat.id}>{cat.name}</option>
                        ))}
                      </Form.Select>
                      {errors.subCategory && <span className="error">{errors.subCategory}</span>}
                    </label>

                    <label className='form-label' style={{ marginBottom: '20px' }}>
                      Select Child Category*
                      <Form.Select aria-label="Default select example" value={childCategory}
                        onChange={(e) => {
                          setChildCategory(e.target.value);
                          setProgress((prevProgress) => ({
                            ...prevProgress,
                            selectCategory: 60
                          }));
                        }}>
                        <option value=""></option>
                        {categories.childCategories.map((cat) => (
                          <option key={cat.id} value={cat.id}>{cat.name}</option>
                        ))}
                      </Form.Select>
                      {errors.childCategory && <span className="error">{errors.childCategory}</span>}
                    </label>
                  </div>

                  <div className="pattern-files">
                    <h4>Pattern File(s)</h4>
                    <div className="pattern-upload"
                      onDragOver={handleDragOver}
                      onDrop={handleDropFront}
                      onDragLeave={handleDragLeave}>
                      {previewFront ? (
                        <>
                          <div className='pattern-side'>FRONT</div>
                          <div style={{ display: 'flex' }}>
                            <img src={previewFront} alt="Front Preview" style={{ width: '92%', height: '100px' }} />
                            <img src={EditDesignIcon}
                              style={{
                                width: "32px",
                                height: "32px",
                                marginLeft: '3%',
                                objectFit: "contain"
                              }} alt="icon" onClick={handleFrontBrowseClick} />
                            <input type="file" id="frontInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleFrontFileChange(e)} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='pattern-side'>FRONT</div>
                          <img src={UploadPattern} alt="pattern" />
                          <div className='pattern-browse'>Drag your file or
                            <span style={{ color: '#0072B9', marginLeft: '4px', cursor: 'pointer' }} onClick={handleFrontBrowseClick}>
                              <input type="file" id="frontInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleFrontFileChange(e)} />
                              browse
                            </span>
                          </div>
                          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                        </>
                      )}
                    </div>
                    <div className="pattern-upload"
                      onDragOver={handleDragOver}
                      onDrop={handleDropBack}
                      onDragLeave={handleDragLeave}
                      style={{ marginTop: '6%' }}>
                      {previewBack ? (
                        <>
                          <div className='pattern-side'>BACK</div>
                          <div style={{ display: 'flex' }}>
                            <img src={previewBack} alt="Back Preview" style={{ width: '92%', height: '100px' }} />
                            <img src={EditDesignIcon}
                              style={{
                                width: "32px",
                                height: "32px",
                                marginLeft: '3%',
                                objectFit: "contain"
                              }} alt="icon" onClick={handleBackBrowseClick} />
                            <input type="file" id="backInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleBackFileChange(e)} />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className='pattern-side'>BACK</div>
                          <img src={UploadPattern} alt="pattern" />
                          <div className='pattern-browse'>Drag your file or
                            <span style={{ color: '#0072B9', marginLeft: '4px', cursor: 'pointer' }} onClick={handleBackBrowseClick}>
                              <input type="file" id="backInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleBackFileChange(e)} />
                              browse
                            </span>
                          </div>
                          <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <Button variant="outline-primary" onClick={handleSelectCategorySubmit}>Save & Continue <img src={SaveBtn} alt="btn" /></Button>
              </>
            )}
            {currentStep === 2 && (
              <>
                <div className="size-form-container">
                  <Form style={{ border: '1px solid #48496433', padding: '30px', borderRadius: '6px' }}>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                      <label className="form-label" style={{ marginBottom: '20px', width: '40%' }}>
                        Choose Size
                        <Form.Select aria-label="Default select example" value={size}
                          onChange={(e) => handleSizeChange(e)}>
                          <option></option>
                          {availableSizes.map((size: any) => (
                            <option key={size.sizeId} value={size.sizeValue}>
                              {size.sizeValue}
                            </option>
                          ))}
                        </Form.Select>
                      </label>
                      <div className="size-options">
                        <div className="button1-group">
                          {labelData.map((label) => (
                            <div
                              key={label.labelTitle}
                              className={`button ${selected === label.labelTitle ? 'active' : ''}`}
                              onClick={() => handleSelect(label.labelTitle)}
                            >
                              {label.labelTitle.charAt(0).toUpperCase() + label.labelTitle.slice(1)}
                            </div>
                          ))}
                        </div>
                        <div className="toggle1-buttons">
                          <div
                            className={`toggle1-button ${activeSizeButton === 'in' ? 'active' : ''}`}
                            onClick={() => handleActiveSizeButton('in')}
                          >
                            in
                          </div>
                          <div
                            className={`toggle1-button ${activeSizeButton === 'cm' ? 'active' : ''}`}
                            onClick={() => handleActiveSizeButton('cm')}
                          >
                            cm
                          </div>
                        </div>
                        <div className="toggle1-buttons">
                          <div
                            className={`toggle1-button ${activeButton === 'IND' ? 'active' : ''}`}
                            onClick={() => handleActiveButton('IND')}
                          >
                            IND
                          </div>
                          <div
                            className={`toggle1-button ${activeButton === 'UK' ? 'active' : ''}`}
                            onClick={() => handleActiveButton('UK')}
                          >
                            UK
                          </div>
                          <div
                            className={`toggle1-button ${activeButton === 'US' ? 'active' : ''}`}
                            onClick={() => handleActiveButton('US')}
                            style={{ border: '1px solid #0072B91A' }}
                          >
                            US
                          </div>
                          <div
                            className={`toggle1-button ${activeButton === 'EU' ? 'active' : ''}`}
                            onClick={() => handleActiveButton('EU')}
                          >
                            EU
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="header-divider" style={{ margin: '10px 0px 20px' }}></div>

                    <Row>
                      <Col sm={5}>
                        <label className="form-label" style={{ width: '100%' }}>
                          Name of the Items
                        </label>
                      </Col>
                      <Col sm={1} style={{ width: '13%' }}>
                        <label className="form-label" style={{ width: '100%' }}>
                          Standard Size
                        </label>
                      </Col>
                      <Col sm={3}>
                        <label className="form-label" style={{ width: '100%' }}>
                          Adjustment (Min/Max)
                        </label>
                      </Col>
                      <Col sm={2}></Col>
                    </Row>

                    {items.map((item, index) => (
                      <Row key={index} className="mb-3">
                        <Col sm={5}>
                          <div style={{ position: 'relative' }}>
                            <Form.Control
                              type="text"
                              name="labelName"
                              value={item.labelName}
                              className="input eye-focus"
                              onChange={(e) => handleInputChange(index, e)} />
                            {item.labelName && item.labelImage != '' && (
                              <img style={{
                                position: 'absolute',
                                top: '55%',
                                right: '20px',
                                transform: 'translateY(-50%)',
                                cursor: 'pointer',
                              }} src={item.toggleOpen === '0' ? CustomSize : ToggleOpen} alt="custom-size" onClick={() => toggleImage(index)} />
                            )}
                          </div>
                        </Col>
                        <Col sm={1} style={{ width: '12%' }}>
                          <Form.Control
                            type="text"
                            name="standardSize"
                            value={item.standardSize}
                            className="input"
                            onChange={(e) => handleInputChange(index, e)} />
                        </Col>
                        <Col sm={3}>
                          <InputGroup>
                            <div style={{ display: 'flex' }}>
                              <Form.Control
                                type="text"
                                name="adjustmentMin"
                                value={item.adjustmentMin}
                                className="input"
                                style={{ width: '100px' }}
                                onChange={(e) => handleInputChange(index, e)} />
                              <Form.Control
                                type="text"
                                name="adjustmentMax"
                                value={item.adjustmentMax}
                                className="input"
                                style={{ width: '100px' }}
                                onChange={(e) => handleInputChange(index, e)} />
                            </div>
                          </InputGroup>
                        </Col>
                        <Col sm={2} className="min-max">
                          <div style={{ display: 'flex' }}>
                            <img style={{ width: '28px' }} src={MinusAdjustment} onClick={() => removeItem(index)} alt="icon" />
                            {item.labelId === '' && item.attachments.length === 0 && (
                              <div className='upload-item' onClick={handleItemBrowseClick}>
                                <img src={Upload} alt="icon" /> Upload
                                <input
                                  id='UploadImage'
                                  type="file"
                                  accept="image/*"
                                  style={{ display: 'none' }}
                                  onChange={(e) => handleItemFileChange(e, index)} />
                              </div>
                            )}
                            {item.labelId === '' && item.attachments.length > 0 && (
                              <div className='upload-image'>
                                {item.attachments.map((file, fileIndex) => (
                                  !file.deleteStatus && (
                                    <img key={fileIndex} src={file.imageData} alt={`Uploaded ${fileIndex + 1}`} />
                                  )
                                ))}
                              </div>
                            )}
                          </div>
                        </Col>
                        {item.toggleOpen === '1' && (<Row>
                          <Col sm={5} style={{ width: '42.6%' }}>
                            <div className="image-toggle-container">
                              <img src={item.labelImage} alt="Toggled Open" />
                            </div>
                          </Col>
                        </Row>
                        )}
                      </Row>
                    ))}
                    <div className="form-actions">
                      <div className="add-more-btn" onClick={addMoreItems}>
                        + Add more
                      </div>
                    </div>
                  </Form>
                </div>
                <div className='design-button'>
                  <Button
                    variant="outline-primary"
                    style={{ marginRight: '2%' }}
                    onClick={() => {
                      setCurrentStep(1);
                      setProgress((prevProgress) => ({
                        ...prevProgress,
                        selectCategory: 80,
                      }));
                    }}
                  >
                    Back
                  </Button>
                  <Button variant="outline-primary" onClick={handleItemInformationSubmit}>Save & Continue<img src={SaveBtn} alt="btn" /></Button>
                </div>
              </>
            )}
            {currentStep === 3 && (
              <>
                <div className="size-form-container">
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='display-size'>{size}</div>
                    <div className="size-options" style={{ marginBottom: '0px' }}>
                      <div className="button1-group">
                        {labelData.map((label) => (
                          <div
                            key={label.labelTitle}
                            className={`button ${selected === label.labelTitle ? 'active' : ''}`}
                            onClick={() => handleSelect(label.labelTitle)}
                          >
                            {label.labelTitle.charAt(0).toUpperCase() + label.labelTitle.slice(1)}
                          </div>
                        ))}
                      </div>
                      <div className="toggle1-buttons">
                        <div
                          className={`toggle1-button ${activeSizeButton === 'in' ? 'active' : ''}`}
                          onClick={() => handleActiveSizeButton('in')}
                        >
                          in
                        </div>
                        <div
                          className={`toggle1-button ${activeSizeButton === 'cm' ? 'active' : ''}`}
                          onClick={() => handleActiveSizeButton('cm')}
                        >
                          cm
                        </div>
                      </div>
                      <div className="toggle1-buttons">
                        <div
                          className={`toggle1-button ${activeButton === 'IND' ? 'active' : ''}`}
                          onClick={() => handleActiveButton('IND')}
                        >
                          IND
                        </div>
                        <div
                          className={`toggle1-button ${activeButton === 'UK' ? 'active' : ''}`}
                          onClick={() => handleActiveButton('UK')}
                        >
                          UK
                        </div>
                        <div
                          className={`toggle1-button ${activeButton === 'US' ? 'active' : ''}`}
                          onClick={() => handleActiveButton('US')}
                          style={{ border: '1px solid #0072B91A' }}
                        >
                          US
                        </div>
                        <div
                          className={`toggle1-button ${activeButton === 'EU' ? 'active' : ''}`}
                          onClick={() => handleActiveButton('EU')}
                        >
                          EU
                        </div>
                      </div>
                    </div>
                  </div>
                  <Table bordered responsive className="size-table">
                    <thead>
                      <tr>
                        <th style={{ paddingLeft: '1.5%' }}>Items</th>
                        <th className="text-center">Size (Standard)</th>
                        <th className="text-center">Adjustable (Min)</th>
                        <th className="text-center">Adjustable (Max)</th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((row, index) => (
                        <tr key={index}>
                          <td className="table-item">{row.labelName}</td>
                          <td className="table-rowinfo">{row.standardSize}</td>
                          <td className="table-rowinfo">{row.adjustmentMin}</td>
                          <td className="table-rowinfo">{row.adjustmentMax}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
                <div className='design-button'>
                  <Button variant="outline-primary" style={{ marginRight: '2%' }}
                    onClick={() => {
                      setCurrentStep(2); // Update the current step
                      setProgress((prevProgress) => ({
                        ...prevProgress,
                        itemInformation: 50, // Update selectCategory to 60%
                      }));
                    }}>Back</Button>
                  <Button variant="outline-primary" onClick={handleSubmission}>Save & Continue<img src={SaveBtn} alt="btn" /></Button>
                </div></>
            )}
            {currentStep === 4 && (
              <><div className='custom-component-container'>
                <Row>
                  <Col md={4}>
                    <Form.Label style={{ marginBottom: '6%' }}>Name of the Components</Form.Label>
                    <div>
                      {inputs.map((input: any, index: any) => (
                        <Form.Group controlId={`componentsName-${index}`} key={index} style={{ marginBottom: '6%', display: 'flex', alignItems: 'center' }}>
                          <Form.Control
                            type="text"
                            placeholder="eg: Material"
                            value={input.name}
                            onChange={(e) => {
                              setProgress((prevProgress) => ({
                                ...prevProgress,
                                customComponent: 60
                              })); handleComponentInputChange(index, e.target.value)
                            }}
                            style={{ width: '300px', marginRight: '15px' }} />
                          {index > 0 && (<img style={{ width: '28px' }} src={MinusAdjustment} onClick={() => removeComponentItem(index)} alt="icon" />)}
                        </Form.Group>
                      ))}
                    </div>
                    {componentError && (
                      <Row>
                        <div className='error'>{componentError}</div>
                      </Row>
                    )}
                    <div className="more-btn d-flex align-items-center justify-content-center" onClick={handleAddMore}>
                      <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
                    </div>
                  </Col>
                  <Col md={5}>
                    <Form.Group controlId="preferredMaterials" className="preferred-materials float-end">
                      <Form.Label>Preferred Materials</Form.Label>
                      <CreatableSelect
                        isMulti
                        onChange={handleChange}
                        value={preferredMaterials}
                        placeholder="eg: Raymond, Cotton"
                        styles={customStyles} />
                      {materialError && (
                        <Row>
                          <div className='error' style={{ marginTop: '10px' }}>{materialError}</div>
                        </Row>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </div><div className='design-button'>
                  <Button variant="outline-primary" style={{ marginRight: '2%' }}
                    onClick={() => {
                      setCurrentStep(3); // Update the current step
                      setProgress((prevProgress) => ({
                        ...prevProgress,
                        itemInformation: 80, // Update selectCategory to 60%
                      }));
                    }} >Back</Button>
                  <Button variant="outline-primary" onClick={handleCustomComponentSubmit}>Save & Continue<img src={SaveBtn} alt="btn" /></Button>
                </div></>
            )}
            {currentStep === 5 && (
              <>
                <div className="aari-designs">
                  {designCategories.map((category) => (
                    <div className={getClassName(category.typeId)} key={category.id}>
                      <img src={category.categoryIcon} alt={category.categoryName} />
                      <p>{category.categoryName}</p>
                    </div>
                  ))}
                </div>
                <div className='upload-title'>Upload Your Choice</div>
                <div className="image-wrapper">
                  <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
                    {previewDesignFront ? (
                      <>
                        <div className='pattern-side'>FRONT</div>
                        <img src={previewDesignFront} alt="Front Preview" style={{ width: '92%', height: '100px' }} />
                      </>
                    ) : (
                      <>
                        <div className='pattern-side'>FRONT</div>
                        <img src={UploadPattern} alt="pattern" />
                        <div className='pattern-browse'>
                          Drag your file or
                          <span style={{ color: '#0072B9', marginLeft: '2px', cursor: 'pointer' }} onClick={handleFrontDesignBrowseClick}>
                            <input type="file" id="frontDesignInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleFrontDesignFileChange(e)} />
                            browse
                          </span>
                        </div>
                        <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                      </>
                    )}
                  </div>
                  <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
                    {previewDesignBack ? (
                      <>
                        <div className='pattern-side'>BACK</div>
                        <img src={previewDesignBack} alt="Back Preview" style={{ width: '92%', height: '100px' }} />
                      </>
                    ) : (
                      <>
                        <div className='pattern-side'>BACK</div>
                        <img src={UploadPattern} alt="pattern" />
                        <div className='pattern-browse'>
                          Drag your file or
                          <span style={{ color: '#0072B9', marginLeft: '2px', cursor: 'pointer' }} onClick={handleBackDesignBrowseClick}>
                            <input type="file" id="backDesignInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleBackDesignFileChange(e)} />
                            browse
                          </span>
                        </div>
                        <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                      </>
                    )}
                  </div>
                  <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
                    {previewHand ? (
                      <>
                        <div className='pattern-side'>HAND</div>
                        <img src={previewHand} alt="Hand Preview" style={{ width: '92%', height: '100px' }} />
                      </>
                    ) : (
                      <>
                        <div className='pattern-side'>HAND</div>
                        <img src={UploadPattern} alt="pattern" />
                        <div className='pattern-browse'>
                          Drag your file or
                          <span style={{ color: '#0072B9', marginLeft: '2px', cursor: 'pointer' }} onClick={handleHandBrowseClick}>
                            <input type="file" id="handInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleHandFileChange(e)} />
                            browse
                          </span>
                        </div>
                        <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                      </>
                    )}
                  </div>
                  <div className="pattern-upload" style={{ width: '285px', padding: '6px 0px' }}>
                    {previewNeck ? (
                      <>
                        <div className='pattern-side'>NECK</div>
                        <img src={previewNeck} alt="Neck Preview" style={{ width: '92%', height: '100px' }} />
                      </>
                    ) : (
                      <>
                        <div className='pattern-side'>NECK</div>
                        <img src={UploadPattern} alt="pattern" />
                        <div className='pattern-browse'>
                          Drag your file or
                          <span style={{ color: '#0072B9', marginLeft: '2px', cursor: 'pointer' }} onClick={handleNeckBrowseClick}>
                            <input type="file" id="neckInput" accept="image/*" style={{ display: "none" }} onChange={(e) => handleNeckFileChange(e)} />
                            browse
                          </span>
                        </div>
                        <div className='pattern-format'>Supported Format : SVG (512 x 512)</div>
                      </>
                    )}
                  </div>
                </div >
                <div className='design-button'>
                  <Button variant="outline-primary" style={{ marginRight: '2%' }} onClick={() => {
                    setProgress((prevProgress) => ({
                      ...prevProgress,
                      customComponent: 80  // Update selectCategory to 20%
                    })); setCurrentStep(4);
                  }}>Back</Button>
                  <Button variant="outline-primary" onClick={handleCustomDesignSubmit}>Save & Continue<img src={SaveBtn} alt="btn" /></Button>
                </div>
              </>
            )}
            {currentStep === 6 && (
              <>
                <div className='custom-component-container'>
                  <Row>
                    <Col md={3}>
                      <Form.Label>Name of the Components</Form.Label>
                    </Col>
                    <Col md={2}>
                      <Form.Label>Name of the Tags</Form.Label>
                    </Col>
                  </Row>
                  {components.map((component, index) => (
                    <div key={index}>
                      <Row style={{ marginBottom: '2%' }}>
                        <Col md={3}>
                          <Form.Group controlId="componentsName">
                            <Form.Control
                              type="text"
                              value={component.name}
                              placeholder="eg: neck model"
                              onChange={(e) => {
                                setProgress((prevProgress) => ({
                                  ...prevProgress,
                                  customDesign: 80,
                                })); handleComponentNameChange(e, index)
                              }}
                              readOnly={index > 0} />
                          </Form.Group>
                        </Col>
                        <Col md={2}>
                          <Form.Group controlId="tagsName">
                            <CreatableSelect
                              isMulti
                              onChange={(newValue) => {
                                setProgress((prevProgress) => ({
                                  ...prevProgress,
                                  customDesign: 90, // Update selectCategory to 60%
                                })); handleChangeTags(newValue, index)
                              }}
                              placeholder="eg: collar"
                              value={component.tags.map(tag => ({ label: tag, value: tag }))}
                              styles={customLabelStyles} />
                          </Form.Group>
                        </Col>
                        <Col md={2} className="d-flex align-items-end">
                          {component.attachments[0].imageData ? (
                            <><img
                              src={component.attachments[0].imageData}
                              alt="uploaded"
                              style={{ maxWidth: '100px', maxHeight: '100px' }}
                              onClick={() => handleBrowseClick(index)} /><input
                                type="file"
                                id={`fileInput-${index}`}
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => handleFileUpload(e, index)} /></>
                          ) : (
                            <Button className="upload-btn" onClick={() => handleBrowseClick(index)}>
                              <img src={Upload} alt="upload-model" /> Upload File
                              <input
                                type="file"
                                id={`fileInput-${index}`}
                                accept="image/*"
                                style={{ display: "none" }}
                                onChange={(e) => {
                                  setProgress((prevProgress) => ({
                                    ...prevProgress,
                                    customDesign: 100, // Update selectCategory to 60%
                                  })); handleFileUpload(e, index)
                                }} />
                            </Button>
                          )}
                        </Col>
                      </Row>
                      {index === 0 && customError && (
                        <Row>
                          <div className='error'>{customError}</div>
                        </Row>
                      )}
                    </div>
                  ))}
                  <Row>
                    <Col md={2}>
                      <div className="more-btn d-flex align-items-center justify-content-center" onClick={handleCustomAddMore}>
                        <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
                      </div>
                    </Col>
                  </Row>
                </div>
                <Button variant="outline-primary" style={{ marginRight: '2%', marginTop: '3%' }} onClick={() => setCurrentStep(5)}>Back</Button>
              </>
            )}
          </div>
        </div>
      </div>
    </div >
  );
}

export default DesignStudio1;