import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./context/PrivateRoute";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./components/auth/login";
import Elements from "./components/auth/elements";
import Elements1 from "./components/auth/Elements1";
import Layout from "./layout";
import Dashboard from "./components/pages/dashboard";
import Category from "./components/pages/category";
import FileUploadComponent from "./components/pages/test";
import Model from "./components/pages/model";
import Mesurementlabel from "./components/pages/measurementlabel";
import MeasurementSize from "./components/pages/measurementsize";
import Childcreate from "./components/pages/childcreate";
import Measurement from "./components/pages/measurement";
import Createmeasurmenttable from "./components/pages/createmeasurmenttable"
import Measurementdashboard from "./components/pages/measurementdashboard"
import DesignStudio from "./components/pages/DesignStudio1";

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/layout" element={<Layout />}>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="design-studio" element={<DesignStudio />} />
            <Route path="category" element={<Category />} />
            <Route path="elements" element={<Elements />} />
            <Route path="elements1" element={<Elements1 />} />
            <Route path="test" element={<FileUploadComponent />} />
            <Route path="model" element={<Model />} />
            <Route path="mesurementlabel" element={<Mesurementlabel />} />
            <Route path="measurementsize" element={<MeasurementSize />} />
            <Route path="childlabel" element={<Childcreate />} />
            <Route path="measurement" element={<Measurement />} />
            <Route path="measurementtable" element={<Createmeasurmenttable />} />
            <Route path="measurementdashboard" element={<Measurementdashboard />} />
            
          </Route>
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
