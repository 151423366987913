import React from 'react';
import { CSVLink } from 'react-csv';

interface DesignData {
  id: string;
  name: string;
  master: string;
  parent: string;
  sub: string;
  saveStatus: string;
  image: string;
}

interface Props {
  data: DesignData[];
}

const ExcelComponent: React.FC<Props> = ({ data }) => {
  const csvData = data.map((item, index) => {
    return {
      "Sl.No": index + 1,
      "Design Name": item.name,           // Match the design name
      "Design ID": item.id,               // Match the design ID
      "Category": item.master,      // Match the master category
      "SubCategory": item.parent,      // Match the parent category
      "Sub Category": item.sub,            // Match the sub category
      "Status": item.saveStatus,           // Match the save status
      "Image": item.image                  // Match the image (optional)
    };
  });

  const currentDate = new Date().toLocaleDateString().replace(/\//g, '-');

  return (
    <CSVLink
      data={csvData}
      filename={`Design_Studio_Report_${currentDate}.csv`} // Corrected filename
      className="btn btn-primary" // Optional: Add styling
      target="_blank" // Opens in a new tab
      style={{marginTop: '-40px', backgroundColor: 'transparent', border: 'none'}}
    >
      Download Excel
    </CSVLink>
  );
};

export default ExcelComponent;
