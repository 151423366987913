import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import category from "../../assets/category.svg";
import { callApi } from "../utills/api";
import leftarrow from "../../assets/leftarrow.svg";
import closeicon from "../../assets/close.svg";
import rarrow from "../../assets/rarrow.svg";
import { showError } from "../utills/sweetAlert";
import { useNavigate } from "react-router-dom";
import backicon from "../../assets/back.svg";

interface PreviousItem {
  id: string;
  categoryName: string;
  typeId: string;
  categoryIcon: string;
  count: string;
}

interface DataItem {
  id: string;
  categoryName: string;
  categoryIcon: string;
  [key: string]: any;
}

interface MeasurementNextData {
  previous: PreviousItem[];
  data: { [key: string]: DataItem[] } | DataItem[]; // The data can be an array or an object
}

interface State {
  measurementNextdata: MeasurementNextData;
  measurementdata: DataItem[];
}

interface SelectedId {
  categoryId: string; // Changed to string to match DataItem id
  categoryKey: string;
}

const Measurement = () => {
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    measurementdata: [],
    measurementNextdata: {
      data: [], // Can be either an array or an object with dynamic keys
      previous: [],
    },
  });
  const [nextTypeId, setNextTypeId] = useState("");
  const [MasterTypeId, setMasterTypeId] = useState("");
  const [ParentTypeId, setParentTypeId] = useState("");
  const [SubTypeId, setSubTypeId] = useState("");
  const [TableSelectId, setTableSelectId] = useState("");

  useEffect(() => {
    MeasurementShowApi();
  }, []);

  const MeasurementShowApi = () => {
    callApi("GET", "categoryMasterSelect.php", "")
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            measurementdata: response.data,
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const handleNext = (id: string, typeId: string) => {
    console.log("typeid--->", typeId);
    setNextTypeId(typeId);
    let req = { id: id };
    callApi("POST", "categoryPreviousSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          const previousReverse = response.previous.reverse();
          setState((prevState) => ({
            ...prevState,
            measurementNextdata: {
              ...response,
              data: response.data,
            },
            measurementdata: previousReverse,
          }));
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  useEffect(() => {
    console.log("use effect set excelData:", state.measurementNextdata);
  }, [state.measurementNextdata]);

  const isArray = (data: any): data is DataItem[] => {
    return Array.isArray(data);
  };

  const isObject = (data: any): data is { [key: string]: DataItem[] } => {
    return !Array.isArray(data) && typeof data === "object" && data !== null;
  };

  const [selectedIds, setSelectedIds] = useState<SelectedId[]>([]);

  useEffect(() => {
    const categoryIds = selectedIds.map((item) => item.categoryId);
    console.log("useEffect set selectedIds:", categoryIds);
  }, [selectedIds]);

  // const handleClick = (categoryId: any, categoryKey: string) => {
  //   setSelectedIds((prev) => {
  //     const existingIndex = prev.findIndex(
  //       (item) => item.categoryKey === categoryKey
  //     );
  //     if (existingIndex !== -1) {
  //       const updatedSelections = [...prev];
  //       updatedSelections[existingIndex] = { categoryId, categoryKey };
  //       return updatedSelections;
  //     } else {
  //       return [...prev, { categoryId, categoryKey }];
  //     }
  //   });
  // };

  const handleClick = (categoryId: string, categoryKey: string) => {
    // categoryId is now string
    setSelectedIds((prev) => {
      const existingIndex = prev.findIndex(
        (item) => item.categoryKey === categoryKey
      );
      if (existingIndex !== -1) {
        const updatedSelections = [...prev];
        updatedSelections[existingIndex] = { categoryId, categoryKey }; // Remove the item if already selected
        return updatedSelections;
      } else {
        return [...prev, { categoryId, categoryKey }]; // Add the item if it's not selected
      }
    });
  };

  const handleNextMeasurement = () => {
    console.log("state.measurementdata", state.measurementdata);
    let masterTypeId = "";
    let parentTypeId = "";
    let subTypeId = "";
    state.measurementdata.forEach((item) => {
      switch (item.typeId) {
        case "1":
          masterTypeId = item.id;
          break;
        case "2":
          if (!parentTypeId) {
            parentTypeId = item.id;
          }
          subTypeId = item.id;
          break;
        case "3":
          subTypeId = item.id;
          break;
        default:
          break;
      }
    });
    setMasterTypeId(masterTypeId);
    setParentTypeId(parentTypeId);
    setSubTypeId(subTypeId);
    const categoryIds = selectedIds.map((item) => item.categoryId);
    let req = {
      masterId: masterTypeId,
      parentId: parentTypeId,
      subId: subTypeId,
      childId: categoryIds,
    };
    callApi("POST", "categorySelectedList.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            measurementNextdata: {
              ...response,
              data: "",
            },
            measurementdata: response.previous,
          }));
          setNextTypeId("4");
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const Savemeasurement = () => {
    const categoryIds = selectedIds.map((item) => item.categoryId);
    let req = {
      masterId: MasterTypeId,
      parentId: ParentTypeId,
      subId: SubTypeId,
      childId: categoryIds,
      saveStatus: "draft",
    };
    callApi("POST", "measurementInsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          console.log("resposnse--->", response);
          console.log("resposnse---inserted>", response.inserted);
          setTableSelectId(response.inserted); // Update state with inserted ID
          TableModelOpen(response.inserted);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  const SavePublishmeasurement = () => {
    const categoryIds = selectedIds.map((item) => item.categoryId);
    let req = {
      masterId: MasterTypeId,
      parentId: ParentTypeId,
      subId: SubTypeId,
      childId: categoryIds,
      saveStatus: "live",
    };
    callApi("POST", "measurementInsert.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          console.log("resposnse--->", response);
          console.log("resposnse---inserted>", response.inserted);
          setTableSelectId(response.inserted); // Update state with inserted ID
          TableModelOpen(response.inserted);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };

  useEffect(() => {
    console.log("use effect set TableSelectId:", TableSelectId);
  }, [TableSelectId]);
  const TableModelOpen = (id: any) => {
    console.log("TableSelectId", id);
    navigate("/layout/measurementtable", {
      state: { TableId: id },
    });
  };

  const isSelected = (categoryId: string) => {
    // categoryId is now string
    return selectedIds.some((item) => item.categoryId === categoryId);
  };

  //   const logPreviousItem = (currentId:any) => {
  //     const currentIndex = state.measurementNextdata.previous.findIndex(item => item.id === currentId);
  //     if (currentIndex > 0) {  // Check if there is a previous item
  //         const previousItem = state.measurementNextdata.previous[currentIndex - 1];
  //         console.log(previousItem.id);

  //         let req = { id: previousItem.id };
  //         callApi("POST", "categoryPreviousSelect.php", req)
  //           .then((res) => res.data)
  //           .then((response) => {
  //             if (response) {
  //               const previousReverse = response.previous.reverse();
  //               setState((prevState) => ({
  //                 ...prevState,
  //                 measurementNextdata: {
  //                   ...response,
  //                   data: response.data,
  //                 },
  //                 measurementdata: previousReverse,
  //               }));
  //             }
  //           })
  //           .catch((err: any) => {
  //             console.error(
  //               "API call error:",
  //               err.response ? err.response.data.error : err.message
  //             );
  //             showError(
  //               "Failed",
  //               err.response ? err.response.data.error : err.message
  //             );
  //           });

  //     } else {
  //         console.log("No previous item available");
  //          setState((prevState: any) => ({
  //             ...prevState,
  //             measurementNextdata: {
  //               data: [],
  //             },
  //             measurementdata: [],
  //           }));
  //           setTimeout(() => {
  //           MeasurementShowApi();
  //           }, 2000);
  //     }
  // };

  const logPreviousItem = (currentId: any) => {
    // Check if the 'previous' array is defined and has content before accessing
    if (
      state.measurementNextdata.previous &&
      state.measurementNextdata.previous.length > 0
    ) {
      const currentIndex = state.measurementNextdata.previous.findIndex(
        (item) => item.id === currentId
      );
      if (currentIndex > 0) {
        const previousItem =
          state.measurementNextdata.previous[currentIndex - 1];
        console.log(previousItem);
        setNextTypeId(previousItem.typeId);
        let req = { id: previousItem.id };
        callApi("POST", "categoryPreviousSelect.php", req)
          .then((res) => res.data)
          .then((response) => {
            if (response && response.previous) {
              const previousReverse = response.previous.reverse();
              setState((prevState) => ({
                ...prevState,
                measurementNextdata: {
                  ...response,
                  data: response.data,
                },
                measurementdata: previousReverse,
              }));
            }
          })
          .catch((err) => {
            console.error(
              "API call error:",
              err.response ? err.response.data.error : err.message
            );
          });
      } else {
        MeasurementShowApi();
        console.log("No previous item available");
        setState((prevState) => ({
          ...prevState,
          measurementNextdata: {
            data: [],
            previous: [],
          },
          measurementdata: [],
        }));
      }
    } else {
      console.log("No data in previous array");
      setState((prevState) => ({
        ...prevState,
        measurementNextdata: {
          data: [],
          previous: [],
        },
        measurementdata: [],
      }));
    }
  };

  return (
    <div>
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              <img
                src={backicon}
                alt=""
                onClick={() => navigate("/layout/measurementdashboard")}
              />
              <span className="category-count">Measurement</span>
            </h1>
          </div>
        </Col>
      </Row>

      <Row>
        {state.measurementNextdata.previous.length === 0 && (
          <Col md="12" lg="12">
            <div className="subheader">
              <span className="category-title">Choose The Master Category</span>
            </div>
          </Col>
        )}

        {state.measurementNextdata.previous.length > 0 && (
          <Col md="12" lg="12">
            <div className="subheader">
              {nextTypeId === "1" ? (
                <>
                  <span className="category-title">
                    Choose The Sub Category
                  </span>
                </>
              ) : nextTypeId === "2" ? (
                <>
                  <span className="category-title">
                    Choose The Sub Category
                  </span>
                </>
              ) : nextTypeId === "3" ? (
                <>
                  <span className="category-title">
                    Choose The Child Category
                  </span>
                </>
              ) : nextTypeId === "4" ? (
                <>
                  <span className="category-title">Choose The Category</span>
                </>
              ) : (
                <span className="category-title">
                  Choose The Parent Category
                </span>
              )}

              {/* <span className="category-title">Choose The Sub Category</span> */}
              {state.measurementNextdata.previous.map((prevItem, index) => (
                <div className="action-buttons" key={index}>
                  <button
                    className="back-button"
                    onClick={() => handleNext(prevItem.id, prevItem.typeId)}
                  >
                    <img src={leftarrow} alt="leftarrow" />
                  </button>
                  {nextTypeId == "4" ? (
                    <>
                      <span className="subcategory-title">
                        {prevItem.categoryName}
                      </span>
                    </>
                  ) : (
                    <>
                      <span className="subcategory-title">
                        {prevItem.categoryName} ({prevItem.count})
                      </span>
                    </>
                  )}

                  <button
                    className="close-button"
                    onClick={() => logPreviousItem(prevItem.id)}
                  >
                    <img src={closeicon} alt="close" />
                  </button>
                </div>
              ))}
            </div>
          </Col>
        )}
        <div className="measurementflex">
          {Array.isArray(state.measurementdata) &&
            state.measurementdata.length > 0 &&
            state.measurementdata.map((data: DataItem, index) => (
              <div key={index} onClick={() => handleNext(data.id, data.typeId)}>
                <div
                  className={
                    data.typeId === "1"
                      ? "wcard"
                      : data.typeId === "2"
                      ? "parentcard"
                      : data.typeId === "3"
                      ? "subcard"
                      : data.typeId === "4"
                      ? "subcard"
                      : "wcard"
                  }
                >
                  {data.typeId === "1" || data.typeId === "2" ? (
                    <>
                      <div>
                        <img
                          src={data.categoryIcon}
                          alt={data.categoryName}
                          className={
                            data.typeId === "2" ? "image-width-70" : ""
                          }
                        />
                      </div>
                      <span className="category-name">{data.categoryName}</span>
                    </>
                  ) : data.typeId === "3" || data.typeId === "4" ? (
                    <>
                      <div>
                        <img
                          src={data.categoryIcon}
                          alt={data.categoryName}
                          className={
                            data.typeId === "3"
                              ? "image-width-70"
                              : data.typeId === "4"
                              ? "image-width-71"
                              : ""
                          }
                        />
                        <span className="category-name">
                          {data.categoryName}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <img src={data.categoryIcon} alt={data.categoryName} />
                      </div>
                      <span className="category-name">{data.categoryName}</span>
                    </>
                  )}
                </div>
              </div>
            ))}
        </div>
      </Row>
      <Row>
        {isArray(state.measurementNextdata.data)
          ? state.measurementNextdata.data.map((data: DataItem, index) => (
              <Col
                md="2"
                lg="2"
                style={{ padding: "2% 2%" }}
                key={index}
                onClick={() => handleNext(data.id, data.typeId)}
              >
                <div
                  className={
                    data.typeId === "1"
                      ? "wcard"
                      : data.typeId === "2"
                      ? "parentcard"
                      : data.typeId === "3"
                      ? "subcard"
                      : data.typeId === "4"
                      ? "childcard"
                      : "parentcard"
                  }
                >
                  {data.typeId === "1" || data.typeId === "2" ? (
                    <>
                      <div>
                        <img src={data.categoryIcon} alt={data.categoryName} />
                      </div>
                      <span className="category-name">{data.categoryName}</span>
                    </>
                  ) : data.typeId === "3" || data.typeId === "4" ? (
                    <>
                      <div>
                        <img
                          src={data.categoryIcon}
                          alt={data.categoryName}
                          className={
                            data.typeId === "3" ? "image-width-70" : ""
                          }
                        />
                        <span className="category-name">
                          {data.categoryName}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div>
                        <img src={data.categoryIcon} alt={data.categoryName} />
                      </div>
                      <span className="category-name">{data.categoryName}</span>
                    </>
                  )}
                </div>
              </Col>
            ))
          : // If `data` is an object with dynamic keys, loop through each category key
            isObject(state.measurementNextdata.data) &&
            Object.keys(state.measurementNextdata.data).map(
              (categoryKey, idx) => {
                const categoryItems = (
                  state.measurementNextdata.data as {
                    [key: string]: DataItem[];
                  }
                )[categoryKey];

                return (
                  <React.Fragment key={idx}>
                    <Col md="12">
                      <div className="category-title">{categoryKey}</div>
                    </Col>

                    {categoryItems.map((data: DataItem, index: number) => (
                      <Col
                        md="2"
                        lg="2"
                        style={{ padding: "2% 2%" }}
                        key={index}
                        onClick={() => handleClick(data.id, categoryKey)}
                      >
                        <div
                          className={
                            data.chidId === "1"
                              ? "subcard"
                              : data.chidId === "2"
                              ? "parentcard"
                              : "subcard"
                          }
                          style={{
                            border: isSelected(data.id)
                              ? "1px solid black"
                              : "none",
                          }}
                        >
                          {data.chidId === "1" ? (
                            <>
                              <div>
                                <img
                                  src={data.categoryIcon}
                                  alt={data.categoryName}
                                  className={
                                    data.chidId === "1" ? "image-width-71" : ""
                                  }
                                />
                                <span className="category-name">
                                  {data.categoryName}
                                </span>
                              </div>
                            </>
                          ) : data.chidId === "2" ? (
                            <>
                              <div>
                                <img
                                  src={data.categoryIcon}
                                  alt={data.categoryName}
                                />
                              </div>
                              <span className="category-name">
                                {data.categoryName}
                              </span>
                            </>
                          ) : (
                            <>
                              <div>
                                <img
                                  src={data.categoryIcon}
                                  alt={data.categoryName}
                                />
                              </div>
                              <span className="category-name">
                                {data.categoryName}
                              </span>
                            </>
                          )}
                        </div>
                      </Col>
                    ))}
                  </React.Fragment>
                );
              }
            )}
      </Row>
      {nextTypeId == "3" && (
        <button className="measurementsave" onClick={handleNextMeasurement}>
          <span className="">
            Next <img src={rarrow} alt="rarrow" />
          </span>
        </button>
      )}

      {nextTypeId == "4" && (
        <>
          <div style={{ display: "flex", gap: "5%" }}>
            <button
              type="button"
              className="save-button"
              onClick={Savemeasurement}
            >
              Save
            </button>

            <button
              type="button"
              className="save-publish-button"
              onClick={SavePublishmeasurement}
            >
              Save & Publish
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Measurement;
