// Sidebar.tsx
import React, { useEffect, useState } from "react";
// import { FaBars, FaTimes, FaHome, FaUser, FaCog } from "react-icons/fa";
import "./Sidebar.css";
import leftarrow from "../../assets/sidebar/left-arrow.svg";
import rightarrow from "../../assets/sidebar/right-arrow.svg";
import dashboardsvg from "../../assets/sidebar/dashboard.svg";
import logosvg from "../../assets/sidebar/logo.svg";
import logoicon from "../../assets/sidebar/logoicon.svg";
import dashboardblacksvg from "../../assets/sidebar/dashboard-black.svg";
import productsvg from "../../assets/sidebar/product.svg";
import productblack from "../../assets/sidebar/productblack.svg";
import menurightarrow from "../../assets/sidebar/menu-right-arrow.svg";
import subwhite from "../../assets/sidebar/subscriberwhite.svg";
import subblack from "../../assets/sidebar/subscriberblack.svg";
import finwhite from "../../assets/sidebar/finwhite.svg";
import finblack from "../../assets/sidebar/finblack.svg";
import teamblack from "../../assets/sidebar/teamblack.svg";
import teamwhite from "../../assets/sidebar/teamwhite.svg";
import memblack from "../../assets/sidebar/memblack.svg";
import memwhite from "../../assets/sidebar/memwhite.svg";
import banwhite from "../../assets/sidebar/banwhite.svg";
import banblack from "../../assets/sidebar/banblack.svg";
import setwhite from "../../assets/sidebar/settwhite.svg";
import setblack from "../../assets/sidebar/settblack.svg";
import notblack from "../../assets/sidebar/notblack.svg";
import notwhite from "../../assets/sidebar/notwhite.svg";
// import user from "../../../assets/sidebar/user.svg";
import closeIcon from "../../assets/sidebar/notwhite.svg";
import { useNavigate } from "react-router-dom";
import { NavLink, Link } from "react-router-dom";
// import { callApi } from "../utils/api";
// import { toast } from "react-toastify";
// import { notification } from "antd";
// import { showSuccess } from "../utils/sweetAlert";
import avatarmale from "../../assets/sidebar/ab3cefaf-b0ea-401e-8aaf-05ba662c438f.jpg";
import logout from "../../assets/logout.svg";
import { showAlert } from "../utills/sweetAlert";

interface notification {
  id: string;
  user_idfk: string;
  user_name: string;
  plan_idfk: string;
  plan_name: string | null;
  task_idfk: string;
  task_name: string;
  product_id: string;
  product_name: string | null;
  product_qty: string;
  paid_amt: string;
  pay_mode: string;
  bussiness_type: string;
  message: string;
  created_dt: string;
}

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState<string>(() => {
    const storedMenu = localStorage.getItem("activeMenu");
    return storedMenu ? storedMenu : "Dashboard";
  });
  const [notificationdata, setnotification] = useState<notification[]>([]);
  const [openNotificationMenu, setOpenNotificationMenu] = useState(false);
  const [selectedBusiness, setSelectedBusiness] = useState<string>("");
  const [todaynoti, setTodayNoti] = useState<notification[]>([]);
  const [earlier, setearlier] = useState<notification[]>([]);
  const [isNotificationsModalOpen, setIsNotificationsModalOpen] =
    useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    if (storedBusiness) {
      setSelectedBusiness(storedBusiness);
    }
    localStorage.setItem("activeMenu", activeMenu);
  }, [activeMenu]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleMenuClick = (menu: string) => {
    const storedBusiness = localStorage.getItem("selectedBusiness");
    sessionStorage.setItem("activesubscribeButtonName", "All Status");
    setActiveMenu(menu);
    if (menu === "Notifications") {
      setIsNotificationsModalOpen(true);
      // getnotifications(selectedBusiness);
    } else {
      setIsNotificationsModalOpen(false);
    }
    if (menu === "logout") {
      // handlelogout();
    }
  };

  // const getnotifications = (businessType: string) => {
  //   const req = {
  //     bussiness_type: businessType,
  //   };
  //   callApi("POST", "staff_notification.php", req)
  //     .then((response) => {
  //       const currentDate = new Date().toDateString(); // Get current date

  //       // Filter notifications based on whether their creation date matches the current date
  //       const todayNotifications = response.data.responseJson.filter(
  //         (notification: any) => {
  //           const createdDate = new Date(
  //             notification.created_dt
  //           ).toDateString();
  //           return createdDate === currentDate;
  //         }
  //       );

  //       const earlier = response.data.responseJson.filter(
  //         (notification: any) => {
  //           const createdDate = new Date(
  //             notification.created_dt
  //           ).toDateString();
  //           return createdDate < currentDate;
  //         }
  //       );

  //       setearlier(earlier);

  //       setTodayNoti(todayNotifications);

  //       // Update all notifications in state
  //       setnotification(response.data.responseJson);
  //     })
  //     .catch((error) => {
  //       console.error("Network error:", error);
  //     });
  // };
  // const handlelogout = () => {
  //   setIsConfirmationModalOpen(false);

  //   let req = {
  //     id: localStorage.getItem("userId"),
  //   };

  //   callApi("POST", "logout.php", req)
  //     .then((response) => {
  //       if (response.data.success === true) {
  //         showSuccess("Success..!", "Logged Out successfully");
  //         navigate("/");
  //         localStorage.clear();
  //         sessionStorage.clear();
  //       } else {
  //         toast.error("Failed to Log Out");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const closeNotificationsModal = () => {
    setIsNotificationsModalOpen(false);
  };

  // const handleNotificationClick = () => {
  //   // Add your logic for handling notification card click here
  //   console.log("Notification card clicked");
  // };

  const handleLogoutone = async () => {
    const isComfirm = await showAlert("Please Confirm", "Are you sure you want to Logout ?", "Yes", "No")
    if (isComfirm) {
        navigate("/")
    }
  }
  const username = sessionStorage.getItem('name');
  const userrole =  sessionStorage.getItem('role');
  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            {isOpen ? (
              <Link to="/layout/dashboard">
                <img
                  src={logosvg}
                  alt="Logo"
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            ) : (
              <Link to="/layout/dashboard">
                <img
                  src={logoicon}
                  alt="Logo"
                  onClick={() => handleMenuClick("Dashboard")}
                />
              </Link>
            )}
            <span className="" onClick={toggleSidebar}>
              {isOpen ? (
                <img src={leftarrow} alt="open" className="arrowicon" />
              ) : (
                <img src={leftarrow} alt="Close" className="arrowicon" />
              )}
            </span>
          </div>
        </div>
      </div>

      <div className="sibebarcontent">
        <ul className="sidebar-menu">
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Dashboard" ? "#0072B9" : "#fff",
                color: activeMenu === "Dashboard" ? "#fff" : "#595959",
              }}
              to="/layout/dashboard"
              onClick={() => handleMenuClick("Dashboard")}
            >
              <img
                src={
                  activeMenu === "Dashboard" ? dashboardsvg : dashboardblacksvg
                }
                alt="dashboard"
              />{" "}
              {isOpen && <span>Dashboard</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Category" ? "#0072B9" : "#fff",
                color: activeMenu === "Category" ? "#fff" : "#595959",
              }}
              to="/layout/category"
              onClick={() => handleMenuClick("Category")}
            >
              <img
                src={activeMenu === "Category" ? productsvg : productblack}
                alt="Category"
              />{" "}
              {isOpen && <span>Category</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Measurements" ? "#0072B9" : "#fff",
                color: activeMenu === "Measurements" ? "#fff" : "#595959",
              }}
              to="/layout/measurementdashboard"
              onClick={() => handleMenuClick("Measurements")}
            >
              <img
                src={activeMenu === "Measurements" ? subwhite : subblack}
                alt="Subscribers"
              />{" "}
              {isOpen && <span>Measurements</span>}
            </NavLink>
          </li>
          <li>
          <NavLink
            className="menu-button"
            style={{
              background:
                activeMenu === "design-studio"
                ? "#0072B9"
                : "#fff",
              color: activeMenu === "design-studio" ? "#fff" : "#595959"
            }}
            to="/layout/design-studio"
            onClick={() => handleMenuClick("design-studio")}
          >
            <img
              src={activeMenu === "design-studio" ? finwhite : finblack}
              alt="design-studio"
            />{" "}
            {isOpen && <span>Design Studio</span>}
          
          </NavLink>
        </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Team" ? "#0072B9" : "#fff",
                color: activeMenu === "Team" ? "#fff" : "#595959",
              }}
              to="/layout/Team"
              onClick={() => handleMenuClick("Team")}
            >
              <img
                src={activeMenu === "Team" ? teamwhite : teamblack}
                alt="Team"
              />{" "}
              {isOpen && <span>Orders</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Membership" ? "#0072B9" : "#fff",
                color: activeMenu === "Membership" ? "#fff" : "#595959",
              }}
              to="/layout/Membership"
              onClick={() => handleMenuClick("Membership")}
            >
              <img
                src={activeMenu === "Membership" ? memwhite : memblack}
                alt="Membership"
              />{" "}
              {isOpen && <span>Clients</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Banner" ? "#0072B9" : "#fff",
                color: activeMenu === "Banner" ? "#fff" : "#595959",
              }}
              to="/layout/Banner"
              onClick={() => handleMenuClick("Banner")}
            >
              <img
                src={activeMenu === "Banner" ? banwhite : banblack}
                alt="Banner"
              />{" "}
              {isOpen && <span>Feedback</span>}
            </NavLink>
          </li>
          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Role" ? "#0072B9" : "#fff",
                color: activeMenu === "Role" ? "#fff" : "#595959",
              }}
              to="/layout/Role"
              onClick={() => handleMenuClick("Role")}
            >
              <img
                src={activeMenu === "Role" ? memwhite : memblack}
                alt="Role"
              />{" "}
              {isOpen && <span>Setting</span>}
            </NavLink>
          </li>

          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Elements1" ? "#0072B9" : "#fff",
                color: activeMenu === "Elements1" ? "#fff" : "#595959",
              }}
              to="/layout/elements1"
              onClick={() => handleMenuClick("Elements1")}
            >
              <img
                src={activeMenu === "Elements1" ? memwhite : memblack}
                alt="Role"
              />{" "}
              {isOpen && <span>Elements1</span>}
            </NavLink>
          </li>

          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Elements" ? "#0072B9" : "#fff",
                color: activeMenu === "Elements" ? "#fff" : "#595959",
              }}
              to="/layout/elements"
              onClick={() => handleMenuClick("Elements")}
            >
              <img
                src={activeMenu === "Elements" ? memwhite : memblack}
                alt="Role"
              />{" "}
              {isOpen && <span>Elements</span>}
            </NavLink>
          </li>

          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Model" ? "#0072B9" : "#fff",
                color: activeMenu === "Model" ? "#fff" : "#595959",
              }}
              to="/layout/model"
              onClick={() => handleMenuClick("Model")}
            >
              <img
                src={activeMenu === "Model" ? memwhite : memblack}
                alt="Role"
              />{" "}
              {isOpen && <span>Model</span>}
            </NavLink>
          </li>

          <li>
            <NavLink
              className="menu-button"
              style={{
                background: activeMenu === "Sleeve" ? "#0072B9" : "#fff",
                color: activeMenu === "Sleeve" ? "#fff" : "#595959",
              }}
              to="/layout/sleeve"
              onClick={() => handleMenuClick("Sleeve")}
            >
              <img
                src={activeMenu === "Sleeve" ? memwhite : memblack}
                alt="Role"
              />{" "}
              {isOpen && <span>Sleeve</span>}
            </NavLink>
          </li>
        </ul>
      </div>

      <ul className="sidebar-menu">
        <li>
          <button
            className="menu-button"
            style={{
              backgroundColor: activeMenu === "user" ? "#642d97" : "#fff",
              color: activeMenu === "user" ? "#fff" : "black",
              alignItems:"unset"
            }}
            onClick={() => setIsConfirmationModalOpen(true)} // Open confirmation modal on click
          >
            <img src={avatarmale} alt="dashboard" style={{ width: "50px" }} />{" "}
            {isOpen && (
              <span style={{ fontSize: "16px", color: "#444444" }}>
              {username}
              {/* Jaisurya */}
              </span>
            )}
          </button>
          {isOpen && (
            <span
              style={{
                fontSize: "16px",
                color: "#444444",
                position: "absolute",
                left: "73px",
                marginTop: "-36px",
              }}
            >
              {userrole}
              {/* SuperAdmin */}
            </span>
          )}
        </li>
        <li>
         
          <div>
            <button className="logout-button"
            onClick={handleLogoutone}>
              <span className="logout-text">Logout</span>
              <img src={logout} alt="Out" className="logouticon" />
            </button>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
