import React, { useState } from "react";

import Table from "react-bootstrap/Table";
import "./model.css";

import CreateIcon from '../../assets/create-icon.svg';
import Icon from '../../assets/icon.svg';
import Arrow from '../../assets/leftarrow.svg';

const Model = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("working");

    setIsModalOpen(true);
    console.log("isModalOpen", isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  return (
    <>
      <div>
        <button className="cardbutton" onClick={handleOpenModal}>
          <span>
            <img src={CreateIcon} alt="" />{" "}
          </span>{" "}
          <p>Create New</p>
        </button>
      </div>

      {isModalOpen && (
        <>
          <button className="modal-close" onClick={handleCloseModal}>
            <img src={Icon} alt="" />
          </button>
        </>
      )}

      {isModalOpen && (
        <>
          <div className="modal-backdrop">
            <div className="modal">
              <div className="tablecon">
                <div className="first">
                  Create Measurement
                  <button className="cardbuttonopen">
                    <span>
                      <img src={CreateIcon} alt="" />{" "}
                    </span>{" "}
                    <p>Create New</p>
                  </button>
                </div>

                <div>
                  {/* <i class="fa-solid fa-arrow-left"></i> */}
                  <img className="icon" src={Arrow} alt=""/>
                </div>

                <div className="name">
                  <p>Update your design parameters</p>
                </div>
              </div>
              <div className="tables">
    
                <Table border={1} className="table-border">

                  <thead>
                    <tr>
                      <th className="table-head">Sizes</th>
                      <th className="table-head-one">50</th>
                      <th className="table-head-one">48</th>
                      <th className="table-head-one">46</th>
                      <th className="table-head-one">44</th>
                      <th className="table-head-one">42</th>
                      <th className="table-head-one">40</th>
                      <th className="table-head-one">38</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="table-head">Front</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="table-left">BODY HEIGHT</td>
                      <td>12.5</td>
                      <td>12</td>
                      <td>11.5</td>
                      <td>11</td>
                      <td>10.5</td>
                      <td>10</td>
                      <td>9.5</td>
                    </tr>
                    <tr>
                      <td className="table-left">UPPER CHEST</td>
                      <td>15</td>
                      <td>15</td>
                      <td>14.5</td>
                      <td>13.5</td>
                      <td>13</td>
                      <td>12.5</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td className="table-left">WAIST</td>
                      <td>15</td>
                      <td>15</td>
                      <td>14.5</td>
                      <td>13.5</td>
                      <td>13</td>
                      <td>12.5</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK HEIGHT</td>
                      <td>6.5</td>
                      <td>6.5</td>
                      <td>6.25</td>
                      <td>5.75</td>
                      <td>5.5</td>
                      <td>5.25</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK WIDTH</td>
                      <td>4</td>
                      <td>4</td>
                      <td>4</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK MID CURVE</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="table-left">ARM HOLE LENGTH</td>
                      <td>8</td>
                      <td>8</td>
                      <td>8</td>
                      <td>7</td>
                      <td>7</td>
                      <td>7</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td className="table-left">ARM HOLE MID CURVE</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="table-left">HALF SHOULDER</td>
                      <td>8.3</td>
                      <td>8.3</td>
                      <td>7.7</td>
                      <td>7.3</td>
                      <td>7.0</td>
                      <td>6.7</td>
                      <td>6.3</td>
                    </tr>
                    <tr>
                      <td className="table-left">SHOULDER WIDTH</td>
                      <td>4.2</td>
                      <td>4.2</td>
                      <td>3.8</td>
                      <td>3.7</td>
                      <td>3.5</td>
                      <td>3.3</td>
                      <td>3.2</td>
                    </tr>
                    <tr>
                      <td className="table-left">APPEX POINT LENGTH</td>
                      <td>12.5</td>
                      <td>12.5</td>
                      <td>11.5</td>
                      <td>11</td>
                      <td>10.5</td>
                      <td>10</td>
                      <td>9.5</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 1 MID POINT</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>3</td>
                      <td>2</td>
                      <td>2</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 1 WIDTH</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.25</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 1 LENGTH</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3.0</td>
                      <td>3.0</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 2 MID POINT</td>
                      <td>5</td>
                      <td>5</td>
                      <td>5</td>
                      <td>5</td>
                      <td>5</td>
                      <td>4</td>
                      <td>4</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 2 WIDTH</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.25</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 2 LENGTH</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3.0</td>
                      <td>3.0</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 3 MID POINT</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 3 WIDTH</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.75</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.25</td>
                    </tr>
                    <tr>
                      <td className="table-left">DART 3 LENGTH</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>4.0</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3.0</td>
                      <td>3.0</td>
                    </tr>
                    <tr>
                      <td className="table-head">Back</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="table-left">BODY HEIGHT</td>
                      <td>15.5</td>
                      <td>15</td>
                      <td>14.5</td>
                      <td>14</td>
                      <td>13.5</td>
                      <td>13</td>
                      <td>12.5</td>
                    </tr>
                    <tr>
                      <td className="table-left">UPPER CHEST</td>
                      <td>15</td>
                      <td>14.5</td>
                      <td>14</td>
                      <td>13.5</td>
                      <td>13</td>
                      <td>12.5</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td className="table-left">WAIST</td>
                      <td>15</td>
                      <td>14.5</td>
                      <td>14</td>
                      <td>13.5</td>
                      <td>13</td>
                      <td>12.5</td>
                      <td>12</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK HEIGHT</td>
                      <td>6.5</td>
                      <td>6.25</td>
                      <td>6</td>
                      <td>5.75</td>
                      <td>5.5</td>
                      <td>5.25</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK WIDTH</td>
                      <td>4</td>
                      <td>4</td>
                      <td>4</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td className="table-left">NECK MID CURVE</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                      <td>1</td>
                    </tr>
                    <tr>
                      <td className="table-left">ARM HOLE LENGTH</td>
                      <td>8</td>
                      <td>8</td>
                      <td>8</td>
                      <td>7</td>
                      <td>7</td>
                      <td>7</td>
                      <td>6</td>
                    </tr>
                    <tr>
                      <td className="table-left">ARM HOLE MID CURVE</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                      <td>1.5</td>
                    </tr>
                    <tr>
                      <td className="table-left">HALF SHOULDER</td>
                      <td>8.3</td>
                      <td>8.0</td>
                      <td>7.7</td>
                      <td>7.3</td>
                      <td>7.0</td>
                      <td>6.7</td>
                      <td>6.3</td>
                    </tr>
                    <tr>
                      <td className="table-left">SHOULDER WIDTH</td>
                      <td>4.1</td>
                      <td>4</td>
                      <td>3.8</td>
                      <td>3.7</td>
                      <td>3.5</td>
                      <td>3.3</td>
                      <td>3.2</td>
                    </tr>
                    <tr>
                      <td className="table-left-back">
                        BACK TUCK POINT HEIGHT
                      </td>
                      <td>4</td>
                      <td>4</td>
                      <td>4</td>
                      <td>3.5</td>
                      <td>3.5</td>
                      <td>3</td>
                      <td>3</td>
                    </tr>
                    <tr>
                      <td className="table-head">Normal Sleeve</td>
                      <td></td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="table-left">SLEEVE LENGTH</td>
                      <td>7</td>
                      <td>7</td>
                      <td>6</td>
                      <td>6</td>
                      <td>6</td>
                      <td>6</td>
                      <td>5</td>
                    </tr>
                    <tr>
                      <td className="table-left-back">
                        SLEEVE AND CIRCUMFERENCE
                      </td>
                      <td>8.8</td>
                      <td>8.5</td>
                      <td>8.2</td>
                      <td>7.8</td>
                      <td>7.5</td>
                      <td>7.2</td>
                      <td>6.8</td>
                    </tr>
                    <tr>
                      <td className="table-left">MID HAND ROUND</td>
                      <td>10.5</td>
                      <td>10.1</td>
                      <td>9.7</td>
                      <td>9.3</td>
                      <td>8.9</td>
                      <td>8.5</td>
                      <td>8.1</td>
                    </tr>
                    <tr>
                      <td className="table-left">CAP HEIGHT</td>
                      <td>4.4</td>
                      <td>4.3</td>
                      <td>4.1</td>
                      <td>3.9</td>
                      <td>3.8</td>
                      <td>3.6</td>
                      <td>3.4</td>
                    </tr>
                    <tr>
                      <td className="table-head">Short Puff</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">SLEEVE LENGTH</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">SLEEVE AND CIRCUMFERENCE</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">MID HAND ROUND</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">CAP HEIGHT</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-head">Elbow Puff</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td className="table-left">SLEEVE LENGTH</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">SLEEVE AND CIRCUMFERENCE</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">MID HAND ROUND</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                    <tr>
                      <td className="table-left">CAP HEIGHT</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                      <td>-</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Model;
