import React, { useEffect, useState } from "react";
import { Col, Form, Pagination, Row } from "react-bootstrap";
import backicon from "../../assets/back.svg";
import plus from "../../assets/plus.svg";
import { useNavigate } from "react-router-dom";
import previous from "../../assets/previousicon.svg";
import next from "../../assets/nexticon.svg";
import search from "../../assets/search.svg";
import woman from "../../assets/woman.svg";
import { callApi } from "../utills/api";
import { showAlert, showError, showSuccess } from "../utills/sweetAlert";
import category from "../../assets/category.svg";


const Measurementdashboard = () => {
  const navigate = useNavigate();
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [countPage, setPageCount] = useState(1);
  const [totalRecords,setTotalRecords]=useState(0);
  const[secondPageNumber,setsecondPageNumber] = useState(0);

  const[firstPageNumber,setFirstPageNumber] = useState(1);

  const [state, setState] = useState({
    measuremenPagetdata: {
      data: [],
    },
  });

  useEffect(() => {
    MeasurementPageShowApi();
  }, []);

  const MeasurementPageShowApi = () => {
    let req = {
      page: countPage,
      limit: itemsPerPage,
      searchTerm: "",
    };

    callApi("POST", "measurementSelect.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setState((prevState) => ({
            ...prevState,
            measuremenPagetdata: response,
          }));
          console.log("state--->", state.measuremenPagetdata);
          setPageCount(response.page);
          setTotalRecords(response.totalRecords);
          const PageCount = Math.round(response.totalRecords / response.limit);
          console.log("PageCount:", PageCount);
          setsecondPageNumber(PageCount);
        }
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  useEffect(() => {
    console.log(
      "use effect set measuremenPagetdata:",
      state.measuremenPagetdata
    );
  }, [state.measuremenPagetdata]);

  const [mesSearchTerm, setMesSearchTerm] = useState<string>("");

  const handleMesSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setMesSearchTerm(e.target.value);

    let req = {
        page: countPage,
        limit: itemsPerPage,
        searchTerm: e.target.value
      };
  
      callApi("POST", "measurementSelect.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setState((prevState) => ({
              ...prevState,
              measuremenPagetdata: response,
            }));
            console.log("state--->", state.measuremenPagetdata);
            setPageCount(response.page);
            setTotalRecords(response.totalRecords);

            const PageCount = Math.round(response.totalRecords / response.limit);
            console.log("PageCount:", PageCount);
            setsecondPageNumber(PageCount);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
  };

  const Nextpage = () => {
    const NextPageCount = Math.round(countPage + 1);
    console.log("NextPageCount:", NextPageCount);
     setFirstPageNumber(NextPageCount);
    let req = {
        page: NextPageCount,
        limit: itemsPerPage,
        searchTerm: mesSearchTerm,
      };
  
      callApi("POST", "measurementSelect.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setState((prevState) => ({
              ...prevState,
              measuremenPagetdata: response,
            }));
            console.log("state--->", state.measuremenPagetdata);
            setPageCount(response.page);
            setTotalRecords(response.totalRecords);
            const PageCount = Math.round(response.totalRecords / response.limit);
            console.log("PageCount:", PageCount);
            setsecondPageNumber(PageCount);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
  }

  const Previous = () => {
    const PreviousPageCount = Math.round(countPage - 1);
    console.log("PreviousPageCount:", PreviousPageCount);
    setFirstPageNumber(PreviousPageCount);

    let req = {
        page: PreviousPageCount,
        limit: itemsPerPage,
        searchTerm: mesSearchTerm,
      };
  
      callApi("POST", "measurementSelect.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setState((prevState) => ({
              ...prevState,
              measuremenPagetdata: response,
            }));
            console.log("state--->", state.measuremenPagetdata);
            setPageCount(response.page);
            setTotalRecords(response.totalRecords);
            const PageCount = Math.round(response.totalRecords / response.limit);
            console.log("PageCount:", PageCount);
            setsecondPageNumber(PageCount);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
  }

  const PageCountChange = (event: any) => {
    setItemsPerPage(event.target.value);
    let req = {
        page: firstPageNumber,
        limit: event.target.value,
        searchTerm: mesSearchTerm,
      };
  
      callApi("POST", "measurementSelect.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setState((prevState) => ({
              ...prevState,
              measuremenPagetdata: response,
            }));
            console.log("state--->", state.measuremenPagetdata);
            setPageCount(response.page);
            setTotalRecords(response.totalRecords);
            const PageCount = Math.round(response.totalRecords / response.limit);
            console.log("PageCount:", PageCount);
            setsecondPageNumber(PageCount);
          }
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });

  }

  const handleViewDetails = (id:any) => {
    console.log("TableSelectId", id);
    navigate("/layout/measurementtable", { 
      state: { TableId: id } 
    });
  };

  const createNew = () =>{
navigate("/layout/measurement")
  }

  const handlePublishapi = async (data:any) =>{
    const isComfirm = await showAlert("Please Confirm", "Are you sure you want to Published this Measurement?", "Yes", "No")
    if (isComfirm) {
      Publishupdate(data);
    }
  }

  const Publishupdate = (data: any) =>{
    let req = {
      id: data,
      status:"live"
    };
    callApi("POST", "measurementStatusUpdate.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response.success==true) {
      showSuccess("Success!", "Category Published Successfully!");
      MeasurementPageShowApi();
        }else{
          console.log("response.error",response.error);
        }
      })
      .catch((err: any) => {
        console.error('API call error:', err.response ? err.response.data.error : err.message);
        showError("Failed",err.response ? err.response.data.error : err.message);
      });
      
  }

  return (
    <main>
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              <span className="category-count">Measurements</span>
            </h1>
            <button className="create-new-btn"
            onClick={createNew} >
              <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} />{" "}
              Create New
            </button>
          </div>
        </Col>
      </Row>

      {/* {totalRecords== 0 && (
        <Row>
          <Col
            lg="12"
            md="12"
            className="d-flex justify-content-center align-items-center vh-100"
          >
            <div className="cardcontainer">
              <img src={category} alt="No Categories" className="cardimage" />
              <p className="cardtext">
                It seems there are no measurements created yet!
              </p>
            </div>
          </Col>
        </Row>
      )} */}
 

      <div className="paginationmeascontainer">
        <div className="items-per-page">
          <label>Show</label>

          <Form.Select
            className="form-control widthcls"
            value={itemsPerPage}
            onChange={PageCountChange}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="30">30</option>
            <option value="40">40</option>
            <option value="50">50</option>
          </Form.Select>
          <span>per page</span>
        </div>
        <div className="page-navigation">
          <span>{firstPageNumber} to {secondPageNumber}</span>
          <button onClick={Previous}>
            <img src={previous} alt="previous" />
          </button>
          <button onClick={Nextpage}>
            <img src={next} alt="next" />
          </button>
        </div>
        <div className="searchdiv">
          <div className="">
            <div className="messearchbar">
              <input type="text" placeholder="Search Measurement"
               value={mesSearchTerm}
               onChange={handleMesSearchChange} />
              <img src={search} alt="search-icon" style={{ width: "22px" }} />
            </div>
          </div>
        </div>
      </div>
   


      <Row style={{ marginTop: "3%" }}>
        {state.measuremenPagetdata.data.length > 0 ? (
          state.measuremenPagetdata.data.map((category: any) => (
            <Col md="3" lg="3" key={category.id}>
              <div className="measurementcard">
                <div className="card-content">
                  <div className="">
                    <img src={category.image} alt="" className="imgmes" />
                  </div>
                  <div className="card-info">
                    <h3 className="meshead">{category.master}</h3>
                    <p className="mespara">
                      {category.parent}, {category.sub},
                      {category.children &&
                        category.children.map((child: any, index: any) => (
                          <span key={index}>
                            {child}
                            {index < category.children.length - 1 ? ", " : ""}
                          </span>
                        ))}
                    </p>
                  </div>
                </div>
                <div className="card-contentmes">
                  <div className="card-actions">
                    <button
                      className="mes-viewbtn"
                       onClick={() => handleViewDetails(category.id)}
                    >
                      View
                    </button>
                    {/* <button className="mes-viewbtnone">Published</button> */}
                    {category.saveStatus === "live" && (
                        <button className="view-btnone">
                          Published
                      </button>
                       )}
                        {category.saveStatus === "draft" && (
                        <button className="view-btnone"
                        onClick={() => handlePublishapi(category.id)}
                        >
                          Save
                      </button>
                       )}
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <p>No Mesurement Categories Available</p>
        )}
      </Row>
    </main>
  );
};

export default Measurementdashboard;
