import React, { useEffect, useState } from "react";
import { Col, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import backicon from "../../assets/back.svg";
import plus from "../../assets/plus.svg";
import { callApi } from "../utills/api";
import { showError } from "../utills/sweetAlert";
import rarrow from "../../assets/rarrow.svg";
function FileUploadComponent() {


  const navigate = useNavigate();
  const location = useLocation();
  const { tableId } = location.state || {};
  useEffect(() => {
    console.log("tableId-->", tableId);
    TableSelect();
  }, [tableId]);

  const backto = () => {
    navigate("/layout/category");
  };
  const newMeasurement = () => {};
  const [activeButton, setActiveButton] = useState("IND");
  const [tableSelectData, setTableData] = useState<any>({});

  const TableSelect = () =>{
    const req = {
        //   id: tableId,
        id: "4",
        countryType: activeButton,
      };
      console.log("request",req);
      
      callApi("POST", "measurementLabelSizeTabel.php", req)
        .then((res) => res.data)
        .then((response) => {
          if (response) {
            setTableData(response);
          }
          console.log("tableSelectData--->", tableSelectData);
        })
        .catch((err: any) => {
          console.error(
            "API call error:",
            err.response ? err.response.data.error : err.message
          );
          showError(
            "Failed",
            err.response ? err.response.data.error : err.message
          );
        });
  }
  const activateButton = (buttonId: any) => {
    console.log("buttonId", buttonId);
    setActiveButton(buttonId);
    const req = {
      //   id: tableId,
      id: "4",
      countryType: buttonId,
    };
    callApi("POST", "measurementLabelSizeTabel.php", req)
      .then((res) => res.data)
      .then((response) => {
        if (response) {
          setTableData(response);
        }
        console.log("tableSelectData--->", tableSelectData);
      })
      .catch((err: any) => {
        console.error(
          "API call error:",
          err.response ? err.response.data.error : err.message
        );
        showError(
          "Failed",
          err.response ? err.response.data.error : err.message
        );
      });
  };
  return (
    <main>
      <Row>
        <Col lg="12" md="12">
          <div className="category-header">
            <h1 className="category-title">
              <img src={backicon} alt="" onClick={backto} />
              <span className="category-count">Create Measurements</span>
            </h1>
            <button className="create-new-btn" onClick={newMeasurement}>
              <img src={plus} alt="plusicon" style={{ marginTop: "-2px" }} />{" "}
              Create New
            </button>
          </div>
        </Col>
      </Row>
      <Row style={{ margin: "2% -1%" }}>
        <Col lg="4" md="4">
          <h4>Update your design parameters</h4>
        </Col>
        <Col lg="3" md="3">
          <div className="toggle-buttons">
            <div
              className={`toggle-button ${
                activeButton === "IND" ? "active" : ""
              }`}
              onClick={() => activateButton("IND")}
            >
              IND
            </div>
            <div
              className={`toggle-button ${
                activeButton === "UK" ? "active" : ""
              }`}
              onClick={() => activateButton("UK")}
            >
              UK
            </div>
            <div
              className={`toggle-button ${
                activeButton === "US" ? "active" : ""
              }`}
              onClick={() => activateButton("US")}
            >
              US
            </div>
            <div
              className={`toggle-button ${
                activeButton === "EU" ? "active" : ""
              }`}
              onClick={() => activateButton("EU")}
            >
              EU
            </div>
          </div>
        </Col>
      </Row>
      <div className="name">
        <p></p>
      </div>

      <div className="">
      <Table border={1} className="table-border">
          <thead>
            <tr>
              <th className="table-head">Sizes</th>
              <th className="table-head-one">50</th>
              <th className="table-head-one">48</th>
              <th className="table-head-one">46</th>
              <th className="table-head-one">44</th>
              <th className="table-head-one">42</th>
              <th className="table-head-one">40</th>
              <th className="table-head-one">38</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="table-head">Front</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
           
            <tr>
              <td className="table-left">DART 1 MID POINT</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>3</td>
              <td>2</td>
              <td>2</td>
            </tr>
            
            <tr>
              <td className="table-left">DART 3 MID POINT</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
              <td>-</td>
            </tr>
            <tr>
              <td className="table-left">DART 3 WIDTH</td>
              <td>1.75</td>
              <td>1.75</td>
              <td>1.75</td>
              <td>1.5</td>
              <td>1.5</td>
              <td>1.5</td>
              <td>1.25</td>
            </tr>
            <tr>
              <td className="table-left">DART 3 LENGTH</td>
              <td>4.0</td>
              <td>4.0</td>
              <td>4.0</td>
              <td>3.5</td>
              <td>3.5</td>
              <td>3.0</td>
              <td>3.0</td>
            </tr>
            <tr>
              <td className="table-head">Back</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td className="table-left">BODY HEIGHT</td>
              <td>15.5</td>
              <td>15</td>
              <td>14.5</td>
              <td>14</td>
              <td>13.5</td>
              <td>13</td>
              <td>12.5</td>
            </tr>
            <tr>
              <td className="table-left">UPPER CHEST</td>
              <td>15</td>
              <td>14.5</td>
              <td>14</td>
              <td>13.5</td>
              <td>13</td>
              <td>12.5</td>
              <td>12</td>
            </tr>
           
            <tr>
              <td className="table-left">NECK WIDTH</td>
              <td>4</td>
              <td>4</td>
              <td>4</td>
              <td>3.5</td>
              <td>3.5</td>
              <td>3</td>
              <td>3</td>
            </tr>
           
           
          </tbody>
        </Table>
      </div>

      <button className="measurementsave">
          <span className="">
            Save <img src={rarrow} alt="rarrow" />
          </span>
        </button>
    </main>
  );
};



export default FileUploadComponent;
