import axios from "axios";

export const API_ENDPOINT = "https://seekreative.zerame.com/api/";

export async function callApi(method: string, path: string, data?: any) {
  const authKey = sessionStorage.getItem('authkey');

  const headers = {
    'Authorization': `Bearer ${authKey}`,
    'Content-Type': 'application/json', 
    'Origin': window.location.origin 
  };

  try {
    if (method.toUpperCase() === "POST") {
      return await axios.post(API_ENDPOINT + path, data, { headers });
    } else if (method.toUpperCase() === "DELETE") {
      return await axios.delete(API_ENDPOINT + path, { headers });
    } else {
      return await axios.get(API_ENDPOINT + path, { headers });
    }
  } catch (error) {
    console.error("API call error:", error);
    throw error;
  }
}
