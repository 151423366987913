import React from 'react'
import { Col, Row } from 'react-bootstrap';
import client from "../../assets/Group.svg";
import categorys from "../../assets/categorys.svg";
import designs from "../../assets/designs.svg";
import clients from "../../assets/clients.svg";
import BarChart from '../../barchart';
import arrow from "../../assets/arrow.svg";


const Dashboard : React.FC = () => { 
  return (
    <div>
    <Row>
                <Col lg="12" md="12">
                  <div className="category-header">
                    <h1 className="category-title">
                      Dashboard <span className="category-count"></span>
                    </h1>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
              <Col lg="3" md="3">
                  <div className="clientcard">
                    <div className="card-content">
                      <div className="client-info">
                        <h3>No. of Clients</h3>
                        <p className="client-count">800</p>
                      </div>
                      <div className="client-image">
                        <img src={clients} alt="Client" 
                        style={{maxWidth:"100px",marginLeft:"-19px"}}/>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="3">
                  <div className="clientcard">
                    <div className="card-content">
                      <div className="client-info">
                        <h3>No. of Orders</h3>
                        <p className="client-count">800</p>
                      </div>
                      <div className="client-image">
                        <img src={client} alt="Client" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="3">
                  <div className="clientcard">
                    <div className="card-content">
                      <div className="client-info">
                        <h3>No. of Designs</h3>
                        <p className="client-count">800</p>
                      </div>
                      <div className="client-image">
                        <img src={designs} alt="Client" />
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg="3" md="3">
                  <div className="clientcard">
                    <div className="card-content">
                      <div className="client-info">
                        <h3>No. of Categories</h3>
                        <p className="client-count">800</p>
                      </div>
                      <div className="client-image">
                        <img src={categorys} alt="Client" />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <br />
              <Row>
              <Col lg="8" md="8">
                  <BarChart />
                </Col>
                <Col lg="4" md="4">
                <div className="">
                    <div className="feedback-header">
                      <span>Feedback</span>
                      <div style={{ display: "flex", gap: "10px" }}>
                        <div className="view-all">View All</div>
                        <div style={{ marginTop: "-2px" }}>
                          <img
                            src={arrow}
                            alt="arrow"
                            style={{ width: "10px" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="feedback-content">
                      {/* Content goes here */}
                    </div>
                  </div>
                </Col>
              </Row>
    </div>
  )
}

export default Dashboard
