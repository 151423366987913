import React, { useEffect, useState } from "react";
import plus from "../../assets/plus.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import remove from "../../assets/remove.svg";
import { Row, Col, Button, Form } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { callApi } from "../utills/api";
import rarrow from "../../assets/rarrow.svg";
import backicon from "../../assets/back.svg";
import CategoryModel from "./categorymodel";
import { showError, showSuccess } from "../utills/sweetAlert";
import "../auth/Elements3.css";
import sleev from "../../assets/sleev.svg";

interface MeasurementItem {
    categoryIcon: string | undefined;
    categoryName: string;
    name: string | undefined;
    icon: string | undefined;
    typeId: string;
    categoryId: string;
    id: string;
    title: string;
    labelName: string;
    labelImage?: string; // Add this to store image URL
    imageData?: string; // Add this to store base64 image data
    imageType?: string; // Add this to store image type
}

const Childcreate = () => {
    const location = useLocation();
    const { parentcategoryId } = location.state || {};
    const { data } = location.state || {};


    const [categoryDetails, setCategoryDetails] = useState<any>({});
    const [childLabelDetails, setChildLabelDetails] = useState<{ [key: string]: string }>({});
    const [dataDetails, setDataDetails] = useState({});
    const [measurements, setMeasurements] = useState<Record<string, MeasurementItem[]>>({});
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [EditCatData, setEditCatData] = useState<any>({});
    const [selectedValue, setSelectedValue] = useState<string | null>(null);
    const [selectedCategoryDetails, setSelectedCategoryDetails] = useState<any>(null);
    useEffect(() => {
        CategorySizeApi();
    }, []);

    const CategorySizeApi = () => {
        const req = {
            parentId: parentcategoryId,
        };
        callApi("POST", "categoryChildList.php", req)
            .then((res) => res.data)
            .then((response) => {
                if (response && response.data) {
                    const { categoryDetails: newCategoryDetails, childLabelDetails: newChildLabelDetails, data } = response;

                    // Set dynamic categoryDetails and childLabelDetails
                    setCategoryDetails(newCategoryDetails);
                    setChildLabelDetails(newChildLabelDetails);

                    // Transform measurements
                    const transformedMeasurements: Record<string, MeasurementItem[]> = {};
                    Object.keys(data).forEach((category) => {
                        const items = data[category];
                        items.forEach((item: MeasurementItem) => {
                            if (!transformedMeasurements[category]) {
                                transformedMeasurements[category] = [];
                            }
                            transformedMeasurements[category].push(item);
                        });
                    });
                    setMeasurements(transformedMeasurements);
                }
            })
            .catch((err: any) => {
                console.error('API call error:', err.response ? err.response.data.error : err.message);
                showError("Failed",err.response ? err.response.data.error : err.message);
              });

    //         let reqest = {
    //             categoryId: data
    //           };
    //           callApi("POST", "categorySingleSelect.php", reqest)
    //             .then((res) => res.data)
    //             .then((response) => {
    //               if (response) {
    //                 setEditCatData("");
    //                 console.log("EditCatData--->",EditCatData);
    //               }
    //             })
    //             .catch((err: any) => {
    //                 console.error('API call error:', err.response ? err.response.data.error : err.message);
    //                 showError("Failed",err.response ? err.response.data.error : err.message);
    //               });
    };

    const handleAddMoreClick = (category: string) => {
        const capitalizedCategory = category.charAt(0).toUpperCase() + category.slice(1);

        Object.keys(childLabelDetails).forEach((key) => {
            if (key.toLowerCase() === capitalizedCategory.toLowerCase()) {
                setSelectedValue(childLabelDetails[key]);
                setSelectedCategoryDetails(categoryDetails); 
                console.log(` Value: ${childLabelDetails[key]}`);
                console.log("Category Details:", categoryDetails); // Print category details
            }
        });
        console.log("selectedValue-->",selectedValue);
        console.log("selectedCategoryDetails--->",selectedCategoryDetails);
        
        categoryModel();
    };

    const categoryModel = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        CategorySizeApi();
      };

    return (
        <main>
            <Row>
                <Col lg="12" md="12">
                    <div className="category-header">
                        <h1 className="category-title">
                            <span className="category-count">Create Category</span>
                        </h1>
                    </div>
                </Col>
            </Row>

            {Object.entries(measurements).map(([category, items]) => (
                <div className="dynamic section" key={category}>
                    <div className="space">
                        <h4>{category.charAt(0).toUpperCase() + category.slice(1)}</h4>
                        <Button className="unique-add-more-button" onClick={() => handleAddMoreClick(category)}>
                            + Add More
                        </Button>
                    </div>
                    <div className="paddingtop">
                        <Row>
                            {items.map(item => (
                                <Col md="3" lg="3" key={item.id} style={{ paddingBottom: "3%" }}>
                                    <div className="childlabelcard">
                                        <div className="spacecenter">
                                            {/* Assuming the correct property for the image is 'icon' and name is 'name' */}
                                            <img
                                                src={`https://seekreative.zerame.com/api/uploads/${item.categoryIcon}`}
                                                style={{ width: "50px" }} alt={item.categoryName} />
                                            <span className="category-name">{item.categoryName}</span>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </div>
            ))}

{isModalOpen && (
            <>
                    <CategoryModel onClose={closeModal}  editData={EditCatData} childlabelData={selectedValue} childData={selectedCategoryDetails} />

            </>
          )}
        </main>
    );
};

export default Childcreate;
