import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  const [week, setWeek] = useState('thisWeek'); // State to toggle between weeks

  // Function to get the dates for the week
  const getWeekLabels = (date:any) => {
    const startOfWeek = new Date(date);
    startOfWeek.setDate(date.getDate() - date.getDay()); // Set to Sunday of this week
    const labels = [];
    for (let i = 0; i < 7; i++) {
      const currentDay = new Date(startOfWeek);
      currentDay.setDate(startOfWeek.getDate() + i);
      labels.push(`${currentDay.getDate()} ${currentDay.toLocaleString('en-US', { weekday: 'short' })}`);
    }
    return labels;
  };

  // Get labels for this week and last week
  const thisWeekLabels = getWeekLabels(new Date()); // Today
  const lastWeekLabels = getWeekLabels(new Date(new Date().setDate(new Date().getDate() - 7))); // Last week

  // Define data for this week and last week
  const dataForThisWeek = {
    labels: thisWeekLabels,
    datasets: [
      {
        label: 'This week',
        data: [200000, 50000, 100000, 30000, 150000, 200000, 120000],
        backgroundColor: '#2979ff', // Blue color
        borderRadius: 5,
        barThickness: 20,
      },
      {
        label: 'Last week',
        data: [100000, 70000, 50000, 90000, 80000, 100000, 60000],
        backgroundColor: '#e0e0e0', // Light grey color
        borderRadius: 5,
        barThickness: 20,
      },
    ],
  };



  return (
    <div style={{ padding: '20px', backgroundColor: '#ffffff', borderRadius: '10px' }}>
      <h3 style={{ marginBottom: '20px', color: '#6e7c91', fontSize: '18px' }}>Statistics</h3>

      <div style={{ marginBottom: '20px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <span style={{ fontSize: '16px', fontWeight: '500' }}>Weekly Comparison</span>
        <div style={{ display: 'flex' }}>
          {/* <button
            onClick={() => setWeek('thisWeek')}
            style={{
              marginRight: '10px',
              backgroundColor: week === 'thisWeek' ? '#2979ff' : '#e0e0e0',
              color: '#fff',
              padding: '5px 15px',
              borderRadius: '5px',
              border: 'none',
            }}
          >
            This Week
          </button> */}
          {/* <button
            onClick={() => setWeek('lastWeek')}
            style={{
              backgroundColor: week === 'lastWeek' ? '#2979ff' : '#e0e0e0',
              color: '#fff',
              padding: '5px 15px',
              borderRadius: '5px',
              border: 'none',
            }}
          >
            Last Week
          </button> */}
        </div>
      </div>

      <Bar data={dataForThisWeek}  />
    </div>
  );
};

export default BarChart;
