import React, { useState } from "react";
import "./element.css";
import { Button, CardBody, Col, Row } from "react-bootstrap";
import client from "../../assets/Group.svg";
import BarChart from "../../barchart";
import category from "../../assets/category.svg";
import upload from "../../assets/upload.svg";
import arrow from "../../assets/arrow.svg";
import plus from "../../assets/plus.svg";
import search from "../../assets/search.svg";
import fillter from "../../assets/fillter.svg";
import down from "../../assets/down.svg";
import leftarrow from "../../assets/leftarrow.svg";
import closeicon from "../../assets/close.svg";
import woman from "../../assets/woman.svg";
import blouse from "../../assets/blouse.svg";
import sleev from "../../assets/sleev.svg";
import remove from "../../assets/remove.svg";
import fileadd from "../../assets/file-add.svg";
import editicon from "../../assets/editicon.svg";
import file from "../../assets/svgcutone.svg";
import duplicate from "../../assets/duplicate.svg";
import rarrow from "../../assets/rarrow.svg";
import toggleIcon from "../../assets/Vector.svg";
import eyeIcon from "../../assets/view-icon1.svg";
import bodyDiagram from "../../assets/bodydigram.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";


const Elements = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    console.log("working");

    setIsModalOpen(true);
    console.log("isModalOpen", isModalOpen);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // add row remove //
  const [sizes, setSizes] = useState<string[]>([]);

  const handleAddSize = () => {
    setSizes([...sizes, ""]); // Add a new empty input field
  };

  const handleRemoveSize = (index: any) => {
    const newSizes = sizes.filter((_, i) => i !== index);
    setSizes(newSizes);
  };

  const handleSizeChange = (index: any, value: any) => {
    const newSizes = sizes.map((size, i) => (i === index ? value : size));
    setSizes(newSizes);
  };

  // image svg front back//
  const [frontFile, setFrontFile] = useState(null);
  const [backFile, setBackFile] = useState(null);

  // Handle File Change
  const handleFileChange = (e: any, type: any) => {
    const file = e.target.files[0];
    if (file && file.type === "image/svg+xml") {
      if (type === "front") {
        setFrontFile(file);
      } else {
        setBackFile(file);
      }
    } else {
      alert("Only SVG files are allowed.");
    }
  };

  //active btn in 3 buttons //
  const [activeButton, setActiveButton] = useState("uk");

  const activateButton = (buttonId: any) => {
    setActiveButton(buttonId);
  };

  // toggele visble image///
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <div>
      <div className="main">
        <div className="container">
          <CardBody className="">
            <div className="loginform">
              <div className="element-container">
                <Row>
                  <Col lg="4" md="4">
                    <label className="label">User Name </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter User Name"
                      name="prjname"
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                  <Col lg="4" md="4">
                    <label className="label">login button </label>
                    <button
                      className="form-control btn btn-primary subbutton"
                      disabled
                    >
                      Submit
                    </button>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="4" md="4">
                    <div className="clientcard">
                      <div className="card-content">
                        <div className="client-info">
                          <h3>No. of Clients</h3>
                          <p className="client-count">800</p>
                        </div>
                        <div className="client-image">
                          <img src={client} alt="Client" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="8" md="8">
                    <BarChart />
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="12" md="12">
                    <div className="cardcontainer">
                      <img
                        src={category}
                        alt="No Categories"
                        className="cardimage"
                      />
                      <p className="cardtext">
                        It seems there are no categories created yet!
                      </p>
                      <button className="cardbutton" onClick={handleOpenModal}>
                        <span className="cardicon">+</span> Create New
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="8" md="8">
                    <div className="feedback-container">
                      <div className="feedback-header">
                        <span>Feedback</span>
                        <div style={{ display: "flex", gap: "10px" }}>
                          <div className="view-all">View All</div>
                          <div style={{ marginTop: "-2px" }}>
                            <img
                              src={arrow}
                              alt="arrow"
                              style={{ width: "10px" }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="feedback-content">
                        {/* Content goes here */}
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

              {isModalOpen && (
                <div className="modal-backdrop">
                  <div className="modal">
                    <div className="modal-header">
                      <button
                        className="modal-close"
                        onClick={handleCloseModal}
                      >
                        &times;
                      </button>
                      <h2>Create Category</h2>
                    </div>
                    <div className="modal-content">
                      <form className="formalign">
                        <div className="form-group">
                          <label>Category Name*</label>
                          <input type="text" className="form-control" />
                        </div>
                        <div className="form-group">
                          <label>Category Type*</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="e.g., Parent Category, Sub Category..."
                          />
                        </div>
                        <div className="form-group">
                          <label>Category Icon</label>
                          <div className="file-upload">
                            <input type="file" id="file-input" />
                            <div className="upload-icon">
                              <img src={upload} alt="Upload Icon" />
                            </div>
                            <p>
                              Drag and Drop file here or{" "}
                              <a href="#" id="file-link">
                                Choose file
                              </a>
                            </p>
                            <p className="file-info">
                              Supported formats: JPEG, PNG, SVG (512 px X 512px)
                            </p>
                          </div>
                        </div>
                        <div className="modal-footer">
                          <button type="button" className="save-button">
                            Save
                          </button>
                          <button type="button" className="save-publish-button">
                            Save & Publish
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              )}
              <div className="element-container">
                <Row>
                  <Col lg="12" md="12">
                    <div className="category-header">
                      <h1 className="category-title">
                        All Category{" "}
                        <span className="category-count">(100)</span>
                      </h1>
                      <button className="create-new-btn">
                        <img
                          src={plus}
                          alt="plusicon"
                          style={{ marginTop: "-2px" }}
                        />{" "}
                        Create New
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="6" md="6">
                    <div className="parentcontainer">
                      <div className="parentheader">
                        <h2>
                          Master Category <span>(10)</span>
                        </h2>
                      </div>
                      <div className="search-filter">
                        <div className="search-bar">
                          <input type="text" placeholder="Search" />
                          <img
                            src={search}
                            alt="search-icon"
                            style={{ width: "22px" }}
                          />
                        </div>
                        <img
                          src={fillter}
                          alt="fillter-icon"
                          style={{ background: "#f2f2f2" }}
                        />
                      </div>

                      <div className="category-card">
                        <div className="card-content">
                          <div className="card-image-placeholder"></div>
                          <div className="card-info">
                            <h3>Men</h3>
                          </div>

                          <div className="dots-menu">&#x22EE;</div>
                        </div>
                        <div className="card-contentone">
                          <p style={{ fontSize: "14px" }}>
                            Created On: 15 Mar 2024
                          </p>
                          <div className="card-actions">
                            <button className="view-btn">View</button>
                            <button className="view-btnone">Published</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Col>

                  <br />
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col md="2" lg="2">
                    <button className="save-btn">
                      Save |{" "}
                      <img src={down} alt="downicon" className="saveimg" />
                    </button>
                  </Col>

                  <Col md="4" lg="4">
                    <div className="wcard">
                      <div className="">
                        <img src={woman} alt="Women Icon" />
                      </div>
                      <span className="category-name">Women</span>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col md="12" lg="12">
                    <div className="subheader">
                      <span className="category-title">
                        Choose The Sub Category
                      </span>
                      <div className="action-buttons">
                        <button className="back-button">
                          <img src={leftarrow} alt="leftarrow" />
                        </button>
                        <span className="subcategory-title">Women (32)</span>
                        <button className="close-button">
                          <img src={closeicon} alt="close" />
                        </button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col md="3" lg="3">
                    <div className="wcard">
                      <div className="">
                        <img src={woman} alt="Women Icon" />
                      </div>
                      <span className="category-name">Women</span>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <div className="parentcard">
                      <div className="">
                        <img src={blouse} alt="Women Icon" />
                      </div>
                      <span className="category-name">Blouse</span>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <div className="subcard">
                      <div className="">
                        <img src={blouse} alt="Women Icon" />
                        <span className="category-name">3 Dart Blouse</span>
                      </div>
                    </div>
                  </Col>
                  <Col md="3" lg="3">
                    <div className="childcard">
                      <div className="">
                        <img src={sleev} alt="Women Icon" />
                        <span className="category-name">Normal Sleeve</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="6" md="6">
                    <div>
                      <h4>Create Sizes*</h4>
                      <p>3 Dart Blouse</p>
                      {sizes.map((size, index) => (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "10px",
                          }}
                        >
                          <input
                            type="text"
                            className="form-control"
                            value={size}
                            onChange={(e) =>
                              handleSizeChange(index, e.target.value)
                            }
                            style={{ marginRight: "10px", padding: "5px" }}
                          />
                          <button
                            onClick={() => handleRemoveSize(index)}
                            style={{ border: "none", background: "none" }}
                          >
                            <img src={remove} alt="removeicon" />
                          </button>
                        </div>
                      ))}

                      <button className="cardbutton" onClick={handleAddSize}>
                        <span className="cardicon">+</span> Add More
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="12" md="12">
                    <div className="category-header">
                      <h1 className="category-title">
                        Create Measurements{" "}
                        <span className="category-count"></span>
                      </h1>
                      <h1 className="category-titlestep">Step 1 of 6</h1>
                    </div>
                  </Col>
                </Row>
              </div>
              <br />
              <div className="element-container">
                <Row>
                  <Col lg="4" md="4">
                    <div className="procontainer">
                      <div className="prolabels">
                        <span className="prolabel">Select Category</span>
                      </div>
                      <div className="progress-container">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{ width: " 25%" }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" md="3">
                    <div className="designupload-container">
                      <label className="designlabel">Design Icon</label>
                      <div className="designupload-box">
                        <input
                          type="file"
                          id="uploadInput"
                          accept="image/*"
                          // onChange={handleFileChange}
                        />
                        <label
                          htmlFor="uploadInput"
                          className="designupload-label"
                        >
                          <div className="designupload-content">
                            <img
                              src={fileadd}
                              alt="fileadd"
                              className="designupload-icon"
                            />
                            <span>Click to upload</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col lg="3" md="3">
                    <label className="designlabel">Design Icon</label>

                    <div className="displayflex">
                      <div className="designcard">
                        <div className="">
                          <img src={blouse} alt="Women Icon" />
                        </div>
                      </div>
                      <img src={editicon} alt="editicon" />
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col md="4" lg="4">
                    <div className="toggle-buttons">
                      <div
                        className={`toggle-button ${
                          activeButton === "uk" ? "active" : ""
                        }`}
                        onClick={() => activateButton("uk")}
                      >
                        UK
                      </div>
                      <div
                        className={`toggle-button ${
                          activeButton === "us" ? "active" : ""
                        }`}
                        onClick={() => activateButton("us")}
                      >
                        US
                      </div>
                      <div
                        className={`toggle-button ${
                          activeButton === "eu" ? "active" : ""
                        }`}
                        onClick={() => activateButton("eu")}
                      >
                        EU
                      </div>
                    </div>
                    <br />
                    <div>
                      <button
                        className="dupicatebutton"
                        onClick={handleOpenModal}
                      >
                        <span className="cardicon">
                          <img src={duplicate} alt="duplicate" />
                        </span>{" "}
                        Duplicate
                      </button>
                    </div>

                    <br />
                    <div>
                      <button
                        className="savecontinuebutton"
                        onClick={handleOpenModal}
                      >
                        <span className="">
                          Save & Continue <img src={rarrow} alt="rarrow" />
                        </span>
                      </button>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="6" md="6">
                    <div className="upload-container">
                      <h3>Pattern File(s)</h3>
                      <div className="file-upload">
                        <div className="file-box">
                          <p>FRONT</p>
                          <input
                            type="file"
                            id="frontFile"
                            accept=".svg"
                            onChange={(e) => handleFileChange(e, "front")}
                          />
                          <label htmlFor="frontFile">
                            <div className="upload-area">
                              <img src={upload} alt="Upload Icon" />
                              <p>
                                Drag your file or{" "}
                                <span className="browse-link">browse</span>
                              </p>
                              <p className="format-info">
                                Supported Format: SVG (512 x 512)
                              </p>
                            </div>
                          </label>
                        </div>

                        <div className="file-box">
                          <p>BACK</p>
                          <input
                            type="file"
                            id="backFile"
                            accept=".svg"
                            onChange={(e) => handleFileChange(e, "back")}
                          />
                          <label htmlFor="backFile">
                            <div className="upload-area">
                              <img src={upload} alt="Upload Icon" />
                              <p>
                                Drag your file or{" "}
                                <span className="browse-link">browse</span>
                              </p>
                              <p className="format-info">
                                Supported Format: SVG (512 x 512)
                              </p>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="8" md="8">
                    <div className="upload-container">
                      <h3>Pattern File(s)</h3>
                      <Row className="patternmargin">
                        <Col lg="6" md="6" className="flexcenter">
                          <div className="pattern-item">
                            <img
                              src={file}
                              alt="Pattern 1"
                              className="pattern-image"
                            />
                            <img src={editicon} alt="editicon" />
                          </div>
                        </Col>
                        <Col lg="6" md="6" className="flexcenter">
                          <div className="pattern-item">
                            <img
                              src={file}
                              alt="Pattern 2"
                              className="pattern-image"
                            />
                            <img src={editicon} alt="editicon" />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="6" md="6">
                    <div className="input-icon d-flex">
                      <input
                        type="text"
                        id="bodyHeight"
                        className="form-control"
                        placeholder="Body Height"
                      />
                      <img
                        onClick={toggleVisibility}
                        src={isVisible ? eyeIcon : toggleIcon}
                        alt="Toggle"
                        style={{ marginLeft: "-10%" }}
                      />
                    </div>
                    {isVisible && (
                      <div id="imageContainer">
                        <img src={bodyDiagram} alt="Body Diagram" />
                      </div>
                    )}
                  </Col>
                </Row>
              </div>
              <div className="element-container">
                <Row>
                  <Col lg="4" md="4">
                    <label className="label">Name Of the Component </label>
                    <input
                      type="text"
                      className="form-control"
                      name="prjname"
                      minLength={5}
                      maxLength={50}
                      required
                    />
                  </Col>
                  <Row>
                    <Col lg="4" md="4">
                      <label className="label">Name Of the Items </label>
                      <input
                        type="text"
                        className="form-control"
                        name="prjname"
                        minLength={5}
                        maxLength={50}
                        required
                      />
                    </Col>
                    <Col lg="2" md="2">
                      <label className="label">Standard Size</label>
                      <input
                        type="text"
                        className="form-control"
                        name="prjname"
                        minLength={5}
                        maxLength={50}
                        required
                      />
                    </Col>
                    <Col lg="3" md="3">
                      <label className="label">Adjustment (Min/Max)</label>
                      <div className="pattern-item">
                        <input
                          type="text"
                          className="form-control"
                          name="prjname"
                          minLength={5}
                          maxLength={50}
                          required
                        />
                        <input
                          type="text"
                          className="form-control"
                          name="prjname"
                          minLength={5}
                          maxLength={50}
                          required
                        />
                      </div>
                    </Col>
                    <Col md={3} className="d-flex align-items-center">
            <Button className="upload-btn">
              <img src={UploadIcon} alt="upload-model" /> Upload Model
            </Button>
          </Col>
                  </Row>
                </Row>
              </div>
            </div>
          </CardBody>
        </div>
      </div>
    </div>
  );
};

export default Elements;
