import React, { useState } from "react";
import Select from 'react-select'
import "./Elements1.css";
import { Col, Table, Row, Form, Button, Badge } from "react-bootstrap";
import design1 from "../../assets/designs/image1.svg";
import design2 from "../../assets/designs/image2.svg";
import design3 from "../../assets/designs/image3.svg";
import design4 from "../../assets/designs/image4.svg";
import design5 from "../../assets/designs/image5.svg";
import design6 from "../../assets/designs/image6.svg";
import design7 from "../../assets/designs/image7.svg";
import design8 from "../../assets/designs/image8.svg";
import design9 from "../../assets/designs/image9.svg";
import design10 from "../../assets/designs/image10.svg";
import design11 from "../../assets/designs/image11.svg";
import RightArrow from "../../assets/icons/right-arrow.svg";
import BlouseIcon from "../../assets/icons/blouse-icon.svg";
import NormalSleeve from "../../assets/icons/normal-sleeve.svg";
import CustomSize from "../../assets/icons/custom-size.svg";
import UploadIcon from "../../assets/icons/upload-icon.svg";
import PreviewIcon from "../../assets/icons/preview-icon.svg";
import AddIcon from "../../assets/icons/add-icon.svg";
import MinusIcon from "../../assets/icons/substract-icon.svg";
import DesignIcon from "../../assets/icons/design-icon.svg";
import logosvg from "../../assets/sidebar/logo.svg";
import UndoIcon from "../../assets/icons/undo-icon.svg";
import RedoIcon from "../../assets/icons/redo-icon.svg";
import leftarrow from "../../assets/icons/menu-left.svg";
import DesignMenu from "../../assets/icons/design-menu.svg";
import SleeveMenu from "../../assets/icons/sleeve-menu-icon.svg";
import NeckMenu from "../../assets/icons/neck-menu-icon.svg";
import VNeckIcon from "../../assets/icons/v-neck.svg";
import SizeMenu from "../../assets/icons/size-menu-icon.svg";
import MaterialMenu from "../../assets/icons/material-menu-icon.svg";
import ProfileImage from "../../assets/designs/image-profile.svg";
import EditProfile from "../../assets/icons/edit-profile.svg";
import ChangePassword from "../../assets/icons/change-password.svg";
import AboutUs from "../../assets/icons/about-us.svg";
import TermsAndConditions from "../../assets/icons/terms-condition.svg";
import PrivacyPolicy from "../../assets/icons/privacy-policy.svg";
import HelpCenter from "../../assets/icons/help-center.svg";
import Announcement from "../../assets/icons/announcement.svg";
import TotalClients from "../../assets/icons/total-client.svg";
import TotalOrders from "../../assets/icons/total-orders.svg";
import TotalItems from "../../assets/icons/total-items.svg";
import TotalYears from "../../assets/icons/total-years.svg";
import OverAllRating from "../../assets/icons/overall-rating.svg";
import FeedbackCollected from "../../assets/icons/feedback-collected.svg";
import FeedbackPerDay from "../../assets/icons/feedback-per-day.svg";

const Elements1 = () => {
  const designs = [
    design1, design2, design3, design4, design5, design1, design2, design3, design4, design5, design1, design2, design3, design4, design5
  ];

  const data = [
    { item: "Sleeve Length", standard: "5", min: "0.8", max: "1.2" },
    { item: "Sleeve End Circumference", standard: "5.2", min: "0.8", max: "1.2" },
    { item: "Mid hand Round", standard: "6", min: "0.8", max: "1.2" },
    { item: "Cap Height", standard: "2.6", min: "0.8", max: "1.2" },
  ];

  const data1 = [
    { item: "Body Height", standard: "7", min: "0.8", max: "1.2" },
    { item: "Upper Chest", standard: "9.5", min: "0.8", max: "1.2" },
    { item: "Waist", standard: "9.5", min: "0.8", max: "1.2" },
    { item: "Neck Height", standard: "3.75", min: "0.8", max: "1.2" },
    { item: "Neck Width", standard: "2", min: "0.8", max: "1.2" },
    { item: "Neck Mid Curve", standard: "1", min: "0.8", max: "1.2" },
    { item: "Arm Hole Length", standard: "5", min: "0.8", max: "1.2" },
    { item: "Arm Hole Mid Curve", standard: "1", min: "0.8", max: "1.2" },
    { item: "Half Shoulder", standard: "7.3", min: "0.8", max: "1.2" },
    { item: "Shoulder Width", standard: "2.3", min: "0.8", max: "1.2" },
    { item: "Appex Point Length", standard: "7", min: "0.8", max: "1.2" },
    { item: "Dart 1 Mid Point", standard: "2", min: "0.8", max: "1.2" },
  ];

  const options = [
    { value: 'Cotton', label: 'Cotton' },
    { value: 'Raymond', label: 'Raymond' },
    { value: 'Linen', label: 'Linen' },
    { value: 'Silk', label: 'Silk' },
    { value: 'Muslin', label: 'Muslin' }
  ]

  const labels = [
    { value: 'Lapel', label: 'Lapel' },
    { value: 'Notch', label: 'Notch' },
  ]

  const [selectedCategory, setSelectedCategory] = useState('Bride');
  const categories = ['Bride', 'Simple', 'Buttas', 'Work'];
  const imageData = {
    'Bride': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Simple': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Buttas': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
    'Work': [design6, design7, design8, design9, design10, design6, design7, design8, design9, design10, design11],
  };

  const [selectedSleeve, setSelectedSleeve] = useState('Normal Sleeve');
  const sleeveTypes = [
    { id: 1, name: 'Normal Sleeve', icon: VNeckIcon },
    { id: 2, name: 'Short Sleeve', icon: VNeckIcon },
    { id: 3, name: 'Puff Sleeve', icon: VNeckIcon },
    { id: 4, name: 'Full Sleeve', icon: VNeckIcon },
  ];

  const [selectedNeck, setSelectedNeck] = useState('V-neck');
  const neckTypes = [
    { id: 1, name: 'V-neck', icon: VNeckIcon },
    { id: 2, name: 'Scoop', icon: VNeckIcon },
    { id: 3, name: 'Square', icon: VNeckIcon },
    { id: 4, name: 'High neck', icon: VNeckIcon },
    { id: 5, name: 'Keyhole', icon: VNeckIcon },
  ];

  const [selectedMaterial, setSelectedMaterial] = useState('Silk');
  const materialTypes = [
    { id: 1, name: 'Silk' },
    { id: 2, name: 'Cotton' },
    { id: 3, name: 'Linen' },
    { id: 4, name: 'Velvet' },
    { id: 5, name: 'Poplin' },
    { id: 6, name: 'Rubia' },
    { id: 7, name: 'Bhandani' },
    { id: 8, name: 'Rayon' },
    { id: 9, name: 'Lace' },
    { id: 10, name: 'Kalamkari' },
  ];

  const clients = [
    {
      id: 1,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 2,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 3,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 4,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
    {
      id: 5,
      name: 'Caroline Heaney',
      email: 'carolineheaney@gmail.com',
      phone: '9845657865',
      imageUrl: ProfileImage,
    },
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = 3;

  const handleClick = (page: any) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const reviews = [
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    },
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    },
    {
      customer: 'Nike Adesanoye',
      date: '5 June, 2024',
      rating: 4.5,
      comment: 'Knowledgeable about products and able to assist with styling suggestions. Quick and efficient checkout process.',
    }
  ];

  const renderStars = (rating: any) => {
    const fullStars = Math.floor(rating);
    const halfStar = rating % 1 !== 0;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {[...Array(fullStars)].map((_, i) => (
          <span key={i} className="star full">★</span>
        ))}
        {halfStar && <span className="star half">★</span>}
        {[...Array(emptyStars)].map((_, i) => (
          <span key={i} className="star empty">★</span>
        ))}
      </>
    );
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
      padding: '10px 0px',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9', // Keep it the same on hover
      },
    }),
  };

  const customLabelStyles = {
    control: (provided: any) => ({
      ...provided,
      minHeight: '50px',
      height: 'auto',
    }),
    multiValue: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B933',
      borderRadius: '30px',
    }),
    multiValueLabel: (provided: any) => ({
      ...provided,
      color: '#0072B9',
      fontSize: '16px',
      fontWeight: '500',
    }),
    multiValueRemove: (provided: any) => ({
      ...provided,
      backgroundColor: '#0072B9',
      borderRadius: '50%',
      width: '14px',
      height: '14px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2px',
      cursor: 'pointer',
      margin: '0px 8px 0px 8px',
      ':before': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(45deg)',
        position: 'absolute',
      },
      ':after': {
        content: '""',
        width: '8px',
        height: '1.5px',
        backgroundColor: 'white',
        transform: 'rotate(-45deg)',
        position: 'absolute',
      },
      ':hover': {
        backgroundColor: '#0072B9', // Keep it the same on hover
      },
    }),
  };

  return (
    <>
      {/* element 33 */}
      <div className="element-container">
        <Row>
          <Col md={1}>
            <div className="add-btn d-flex align-items-center justify-content-center">
              <img style={{ width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" />
            </div>
          </Col>
          <Col md={6}>
            <div className="more-btn d-flex align-items-center justify-content-center">
              <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
            </div>
          </Col>
        </Row>
      </div>
      {/* element 34 */}
      <div className="element-container">
        <Table bordered responsive className="size-table">
          <thead>
            <tr>
              <th style={{ paddingLeft: '1.5%' }}>Items</th>
              <th className="text-center">Size (Standard)</th>
              <th className="text-center">Adjustable (Min)</th>
              <th className="text-center">Adjustable (Max)</th>
            </tr>
          </thead>
          <tbody>
            {data.map((row, index) => (
              <tr key={index}>
                <td className="table-item">{row.item} <img src={CustomSize} alt="custom-size" /></td>
                <td className="table-rowinfo">{row.standard}</td>
                <td className="table-rowinfo">{row.min}</td>
                <td className="table-rowinfo">{row.max}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* element 35 */}
      <div className="element-container">
        <Table bordered responsive className="size-table">
          <thead>
            <tr>
              <th style={{ paddingLeft: '1.5%' }}>Items</th>
              <th className="text-center">Size (Standard)</th>
              <th className="text-center">Adjustable (Min)</th>
              <th className="text-center">Adjustable (Max)</th>
            </tr>
          </thead>
          <tbody>
            {data1.map((row, index) => (
              <tr key={index}>
                <td className="table-item">{row.item}</td>
                <td className="table-rowinfo">{row.standard}</td>
                <td className="table-rowinfo">{row.min}</td>
                <td className="table-rowinfo">{row.max}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {/* element 36 */}
      <div className="element-container">
        <Row>
          <Col md={12} className="d-flex align-items-center justify-content-center">
            <Form.Group controlId="preferredMaterials" className="preferred-material float-end">
              <Form.Label>Preferred Materials</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg: Raymond, Cotton"
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      {/* element 37 */}
      <div className="element-container">
        <Row>
          <Col md={3}>
            <Form.Group controlId="componentsName">
              <Form.Label>Name of the Components</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg: neck model"
              />
            </Form.Group>
          </Col>
          <Col md={2}>
            <Form.Group controlId="tagsName">
              <Form.Label>Name of the Tags</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg: collar"
              />
            </Form.Group>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Button className="upload-btn">
              <img src={UploadIcon} alt="upload-model" /> Upload Model
            </Button>
          </Col>
          <Col md={5}>
            <Form.Group controlId="preferredMaterials" className="preferred-materials float-end">
              <Form.Label>Preferred Materials</Form.Label>
              <Form.Control
                type="text"
                placeholder="eg: Raymond, Cotton"
              />
            </Form.Group>
          </Col>
        </Row>
      </div>
      {/* element 38 */}
      <div className="element-container">
        <Button className="preview-btn d-flex align-items-center">
          <img src={PreviewIcon} alt="preview" /> Preview
        </Button>
      </div>
      {/* element 39 */}
      <div className="element-container">
        <Row>
          <Col md={8}>
            <Row className="row-bottom">
              <Col md={5}>
                <Form.Group controlId="componentsName">
                  <Form.Label>Name of the Components</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="eg: neck model"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="tagsName">
                  <Form.Label>Name of the Tags</Form.Label>
                  <Select options={labels} isMulti placeholder="eg: collar" styles={customLabelStyles} />
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex align-items-end">
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '65%' }}>
                  <Button className="design-btn">
                    <img src={DesignIcon} alt="design-image" />
                  </Button>
                  <div className="minus-btn d-flex align-items-center justify-content-center">
                    <img style={{ width: '12px', height: '12px' }} src={MinusIcon} alt="minus-icon" />
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="row-bottom">
              <Col md={5}>
                <Form.Group controlId="componentsName">
                  <Form.Control
                    type="text"
                    placeholder="eg: neck model"
                  />
                </Form.Group>
              </Col>
              <Col md={4}>
                <Form.Group controlId="tagsName">
                  <Select options={labels} isMulti placeholder="eg: collar" styles={customLabelStyles} />
                </Form.Group>
              </Col>
              <Col md={3} className="d-flex align-items-end">
                <Button className="upload-btn">
                  <img src={UploadIcon} alt="upload-model" /> Upload Model
                </Button>
              </Col>
            </Row>
          </Col>
          <Col md={4}>
            <Form.Group controlId="preferredMaterials" className="preferred-materials float-end">
              <Form.Label>Preferred Materials</Form.Label>
              <Select options={options} isMulti placeholder="eg: Raymond, Cotton" styles={customStyles} />
            </Form.Group>
          </Col>
        </Row>
        <Row className="row-bottom">
          <Col md={6}>
            <div className="more-btn d-flex align-items-center justify-content-center">
              <img style={{ marginRight: '10px', width: '12px', height: '12px' }} src={AddIcon} alt="add-icon" /> Add more
            </div>
          </Col>
        </Row>
      </div>
      {/* element 40 */}
      <div className="element-container">
        <h2 className="section-heading">Aari Designs</h2>
        <div className="aari-designs">
          <div className="parent-card">
            <img src={BlouseIcon} alt="Blouse" />
            <p>Blouse</p>
          </div>
          <div className="sub-card">
            <img src={BlouseIcon} alt="3 Dart Blouse" />
            <p>3 Dart Blouse</p>
          </div>
          <div className="child-card">
            <img src={NormalSleeve} alt="Normal Sleeve" />
            <p>Normal Sleeve</p>
          </div>
        </div>
      </div>
      {/* element 41 */}
      <div className="element-container">
        <Row className="design-header">
          <Col>
            <div className="design-title">Select Designs</div>
          </Col>
          <Col className="text-end">
            <div className="design-view">View all
              <img src={RightArrow} alt="View-all" />
            </div>
          </Col>
        </Row>
        <Row className="design-grid">
          {designs.map((design, index) => (
            <Col key={index} xs={6} sm={4} md={3} lg={2} className="mb-4">
              <div className="design-item">
                <img src={design} alt={`Design ${index + 1}`} className="img-fluid" />
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {/* element 42 */}
      <div className="element-container">
        <div className="header-bar d-flex align-items-center justify-content-between shadow-sm">
          <div className="d-flex align-items-center">
            <button className="menu-btn">
              <img src={leftarrow} alt="logo" className="logo" />
            </button>
            <img src={logosvg} alt="logo" className="logo" />
            <div className="btn-container">
              <button className="icon-btn">
                <img src={UndoIcon} alt="undo" />
              </button>
              <button className="icon-btn" style={{ marginLeft: '5px' }}>
                <img src={RedoIcon} alt="redo" />
              </button>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <span className="blouse-text">(3 Dart Blouse)</span>
            <Button variant="primary" className="save-btn mx-3">Save</Button>
            <Button variant="success" className="publish-btn">Save & Publish</Button>
          </div>
        </div>
      </div>
      {/* element 45 */}
      <div className="element-container">
        <div className="btn1-container">
          <button className="icon-btn">
            <img src={UndoIcon} alt="undo" />
          </button>
          <button className="icon-btn" style={{ marginLeft: '5px' }}>
            <img src={RedoIcon} alt="redo" />
          </button>
        </div>
      </div>
      {/* element 46 */}
      <div className="element-container">
        <div className="menu-container">
          <div className="menu-item">
            <img src={DesignMenu} alt="design" />
            <span>Design</span>
          </div>
          <div className="menu-item active">
            <img src={SleeveMenu} alt="sleeve" />
            <span>Sleeve</span>
          </div>
          <div className="menu-item">
            <img src={NeckMenu} alt="neck" />
            <span>Neck</span>
          </div>
          <div className="menu-item">
            <img src={SizeMenu} alt="size" />
            <span>Size</span>
          </div>
          <div className="menu-item">
            <img src={MaterialMenu} alt="material" />
            <span>Material</span>
          </div>
        </div>
      </div>
      {/* element 47 */}
      <div className="element-container">
        <div style={{ width: '38%' }}>
          <div className="tab-menu">
            {categories.map((category) => (
              <button
                key={category}
                onClick={() => setSelectedCategory(category)}
                className={selectedCategory === category ? 'active-tab' : ''}
              >
                {category}
              </button>
            ))}
          </div>
          <div className="image-grid">
            {imageData[selectedCategory as keyof typeof imageData].map((imageSrc: any, index: any) => (
              <img key={index} src={imageSrc} alt={`Design ${index}`} className="design-image" />
            ))}
          </div>
        </div>
      </div>
      {/* element 48 */}
      <div className="element-container">
        <div className="sleeve-container">
          {sleeveTypes.map((sleeve) => (
            <button
              key={sleeve.id}
              className={`sleeve-button ${selectedSleeve === sleeve.name ? 'active' : ''}`}
              onClick={() => setSelectedSleeve(sleeve.name)}
            >
              <img src={sleeve.icon} alt={`${sleeve.name} icon`} className="sleeve-icon" />
              <span>{sleeve.name}</span>
            </button>
          ))}

          {[...Array(10)].map((_, index) => (
            <button key={index + 5} className="sleeve-button">
              <img src={NormalSleeve} alt="Normal Sleeve icon" className="sleeve-icon" />
              <span>Normal Sleeve</span>
            </button>
          ))}
        </div>
      </div>
      {/* element 49 */}
      <div className="element-container">
        <div className="neck-container">
          {neckTypes.map((neck) => (
            <button
              key={neck.id}
              className={`neck-button ${selectedNeck === neck.name ? 'active' : ''}`}
              onClick={() => setSelectedNeck(neck.name)}
            >
              <img src={neck.icon} alt={`${neck.name} icon`} className="neck-icon" />
              <span>{neck.name}</span>
            </button>
          ))}

          {[...Array(10)].map((_, index) => (
            <button key={index + 5} className="neck-button">
              <img src={VNeckIcon} alt="V neck icon" className="neck-icon" />
              <span>Keyhole</span>
            </button>
          ))}
        </div>
      </div>
      {/* element 51 */}
      <div className="element-container">
        <div className="material-container">
          {materialTypes.map((material) => (
            <button
              key={material.id}
              className={`material-button ${selectedMaterial === material.name ? 'active' : ''}`}
              onClick={() => setSelectedMaterial(material.name)}
            >
              <span>{material.name}</span>
            </button>
          ))}
        </div>
      </div>
      {/* element 54 */}
      <div className="element-container">
        <div className="client-list-container">
          <div className="client-list-header">
            <h2>New Client <Badge bg="primary">9</Badge></h2>
            <button className="add-client-button">+</button>
          </div>
          <div className="client-list">
            {clients.map((client: any, index: any) => (
              <div
                key={client.id}
                className='client-card'
              >
                <div style={{ display: 'flex', alignItems: 'center', borderBottom: '1px solid #48496433', padding: '4px 14px 12px', marginBottom: '8px' }}>
                  <img src={client.imageUrl} alt={client.name} className="client-image" />
                  <div className="client-name">{client.name}</div>
                </div>
                <div className="client-details">
                  <div>
                    <p style={{ fontWeight: '500' }}>Email Id</p>
                    <p>{client.email}</p>
                  </div>
                  <div style={{ marginLeft: '20%' }}>
                    <p style={{ fontWeight: '500' }}>Phone</p>
                    <p>{client.phone}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* element 55 */}
      <div className="element-container">
        <div className="pagination-container">
          <button
            className="pagination-btn"
            style={{ width: '70px', textAlign: 'center' }}
            onClick={() => handleClick(currentPage - 1)}
          >
            Previous
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index + 1}
              className={`pagination-btn ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => handleClick(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-btn"
            style={{ width: '70px', textAlign: 'center' }}
            onClick={() => handleClick(currentPage + 1)}
          >
            Next
          </button>
        </div>
      </div>
      {/* element 56 */}
      <div className="element-container">
      <div className="reviews-table-container">
      <table className="reviews-table">
        <thead>
          <tr>
          <th style={{ width: '10%' }}></th>
            <th style={{ width: '30%' }}>Customer</th>
            <th style={{ width: '60%' }}>Ratings and Comments</th>
          </tr>
        </thead>
        <tbody>
          {reviews.map((review, index) => (
            <tr key={index}>
              <td></td>
              <td>
                <strong style={{fontSize: '16px', fontWeight: '500', color: '#484964'}}>{review.customer}</strong>
                <br />
                <span style={{fontSize: '12px', fontWeight: '500', color: '#484964'}}>{review.date}</span>
              </td>
              <td>
                <p className="rating-text">Very good</p>
                <div className="rating-stars">
                  {renderStars(review.rating)}
                </div>
                <p className="rating-comment">{review.comment}</p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
      </div>
      {/* element 59 */}
      <div className="element-container">
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          <div className="feedback-card">
            <div className="icon"><img src={OverAllRating} alt="icon" /></div>
            <div className="info">
              <p>Total Orders</p>
              <p>4.5</p>
            </div>
          </div>
          <div className="feedback-card">
            <div className="icon"><img src={FeedbackCollected} alt="icon" /></div>
            <div className="info">
              <p>Total Items</p>
              <p>4.5k</p>
            </div>
          </div>
          <div className="feedback-card">
            <div className="icon"><img src={FeedbackPerDay} alt="icon" /></div>
            <div className="info">
              <p>Total Years</p>
              <p>20</p>
            </div>
          </div>
        </div>
      </div>
      {/* element 60 */}
      <div className="element-container">
        <div className="settings">
          <ul>
            <li><img src={EditProfile} alt="icon" /> Edit Profile</li>
            <li><img src={ChangePassword} alt="icon" /> Change Password</li>
            <li className="active"><img src={AboutUs} alt="icon" /> About Us</li>
            <li><img src={TermsAndConditions} alt="icon" /> Terms and Conditions</li>
            <li><img src={PrivacyPolicy} alt="icon" /> Privacy Policy</li>
            <li><img src={HelpCenter} alt="icon" /> Help Centre</li>
            <li><img src={Announcement} alt="icon" /> Announcement</li>
          </ul>
        </div>
      </div>
      {/* element 61 */}
      <div className="element-container">
        <div className="profile-card">
          <div className="icon"><img src={ProfileImage} alt="icon" /></div>
          <div className="info">
            <p>Profile Name</p>
            <p>Admin</p>
          </div>
        </div>
      </div>
      {/* element 62 */}
      <div className="element-container">
        <Row style={{ width: '80%', marginBottom: '20px' }}>
          <Col md={6}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">First Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="First Name"
                className="input-wrapper"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">Last Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Last Name"
                className="input-wrapper"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ width: '80%', marginBottom: '20px' }}>
          <Col md={12}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">Email Id</Form.Label>
              <Form.Control
                type="text"
                placeholder="Email Id"
                className="input-wrapper"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ width: '80%', marginBottom: '20px' }}>
          <Col md={12}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">Address</Form.Label>
              <Form.Control
                type="text"
                placeholder="Address"
                className="input-wrapper"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ width: '80%', marginBottom: '20px' }}>
          <Col md={12}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">Contact Number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Contact Number"
                className="input-wrapper"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row style={{ width: '80%', marginBottom: '20px' }}>
          <Col md={6}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">City</Form.Label>
              <Form.Select aria-label="Default select example" className="input-wrapper">
                <option>City</option>
                <option value="1">City 1</option>
                <option value="2">City 2</option>
                <option value="3">City 3</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group controlId="componentsName">
              <Form.Label className="input-label">State</Form.Label>
              <Form.Select aria-label="Default select example" className="input-wrapper">
                <option>State</option>
                <option value="1">State 1</option>
                <option value="2">State 2</option>
                <option value="3">State 3</option>
              </Form.Select>
            </Form.Group>
          </Col>
        </Row>
      </div>
      {/* element 63 */}
      <div className="element-container">
        <div className="content">
          <h2>About Us</h2>
          <p>Welcome to [Your Fashion Brand Name], where creativity meets style and innovation. We are a passionate team of fashion enthusiasts dedicated to bringing your style aspirations to life.</p>
          <p>At [Your Fashion Brand Name], we believe that fashion is more than just clothing; it's a form of self-expression, an art, and a reflection of individuality. With this ethos at our core, we strive to empower every individual to embrace their unique style journey.</p>
          <p>Our journey began with a simple yet powerful vision: to redefine the fashion landscape by creating designs that inspire, empower, and celebrate diversity. From haute couture to everyday essentials, we curate collections that resonate with modern trends while staying true to timeless elegance.</p>
          <div className="stats">
            <div className="stat-card">
              <div className="icon"><img src={TotalClients} alt="icon" /></div>
              <div className="info">
                <h3>Total Clients</h3>
                <p>20000+</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="icon"><img src={TotalOrders} alt="icon" /></div>
              <div className="info">
                <h3>Total Orders</h3>
                <p>30000+</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="icon"><img src={TotalItems} alt="icon" /></div>
              <div className="info">
                <h3>Total Items</h3>
                <p>5000+</p>
              </div>
            </div>

            <div className="stat-card">
              <div className="icon"><img src={TotalYears} alt="icon" /></div>
              <div className="info">
                <h3>Total Years</h3>
                <p>10+</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Elements1;
